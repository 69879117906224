import React from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import Logout from '../../users/components/Logout';
import AllConfigurationparameters from '../../configurationParameter/components/AllConfigurationParameters';
import AllUserAccounts from '../../users/components/AllUserAccounts';
import AllGeometricElementType from '../../geometricElements/components/AllGeometricElementType';
import AllAttributeType from '../../geometricElements/components/AllAttributeType';
import GeometricElementsByGeometricElementType from '../../geometricElements/components/GeometricElementsByGeometricElementType';
import OpenLayersMap from '../../geometricElements/components/OpenLayersMap';
import Component404 from './Component404';
import { ErrorDialog, LoadingDialog } from '../../common';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../selectors';
import * as actions from '../actions';
import AllGeometricLayerGroup from '../../geometricElements/components/AllGeometricLayerGroup';
import AllGeometricLayer from '../../geometricElements/components/AllGeometricLayer';
import GeometricElementDetails from '../../geometricElements/components/GeometricElementDetails';
import AllGeneralAttachedFile from '../../attachedFile/components/AllGeneralAttachedFile';
import AllGeometricElementTypeStyles from '../../geometricElements/components/AllGeometricElementTypeStyles';
import users from '../../users';

const Body = () => {

    let location = useLocation();
    let error = useSelector(selectors.getError);
    let dispatch = useDispatch();
    let authenticatedUser = useSelector(users.selectors.getLogin);

    return (
        <div id="container">
            <ErrorDialog
                error={error}
                onClose={() => {
                    dispatch(actions.error(null));
                }}
            />
            <LoadingDialog />
            <Switch location={location}>
                <Route exact path="/" component={OpenLayersMap} />
                <Route exact path="/users/logout" component={Logout} />
                <Route exact path="/admin/elementType" component={AllGeometricElementType} />
                <Route exact path="/admin/attributeType" component={AllAttributeType} />
                <Route exact path="/admin/configurationParameter" component={AllConfigurationparameters} />
                <Route exact path="/admin/users" component={AllUserAccounts} />
                <Route exact path="/geometric_elements/:id" component={() => <GeometricElementsByGeometricElementType key={location.pathname} />} />
                <Route exact path="/geometric_elements/:id/myElements" component={() => <GeometricElementsByGeometricElementType key={location.pathname} />} />
                <Route exact path="/geometric_element/details/:id" component={() => <GeometricElementDetails key={authenticatedUser} />} />
                {/*When user logs in it's sent to this route which it will make a redirect to /. This is done to force 
                geographical viewer to render again*/}
                <Route exact path="/users/loggedIn">
                    <Redirect to="/" />
                </Route>
                {/*When user inserts a geometric element from navbar it will be redirected to this route which it will make a redirect to /.
                This is done to force geographical viewer to render again because HashRouter can't send props between compoents when they were
                rendered*/}
                <Route exact path="/insertFromNavBar">
                    <Redirect to={{
                        pathname: "/",
                        state: location.state
                    }}
                    />
                </Route>
                <Route exact path="/admin/layerGroup" component={AllGeometricLayerGroup} />
                <Route exact path="/admin/layers" component={AllGeometricLayer} />
                <Route exact path="/admin/generalAttachedFiles" component={AllGeneralAttachedFile} />
                <Route exact path="/admin/geometricElementTypeStyles" component={AllGeometricElementTypeStyles} />
                <Route component={Component404} />
            </Switch>
        </div>
    );

};

export default Body;