import React, { useEffect } from 'react';
import GeographicalViewer from './GeographicalViewer';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as parameterSelectors from '../../configurationParameter/selectors'
import * as userSelectors from '../../users/selectors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        parameters: parameterSelectors.getTotalConfigurationParameters(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricElementType: selectors.getTotalGeometricElementType(state),
        geometricElementType: selectors.getGeometricElementType(state),
        listProvinces: selectors.getListProvinces(state),
        listCouncils: selectors.getListCouncils(state),
        listParishes: selectors.getListParishes(state),
        listEntityPopulation: selectors.getListEntityPopulations(state),
        listCounties: selectors.getListCounties(state),
        listAllGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state),
        authenticatedUser: userSelectors.getUser(state)
    }
}

const OpenLayersMap = (props) => {

    useEffect(() => {
        jQuery('#mainFooter').scrollTop(0);
    })

    let openAddGeometricElementForm = (map, geometry) => {
        jQuery('#map-container').css('margin-right', '30vw');
        jQuery('#addGeometricElementContainer').css('display', 'block');
        map.updateSize();
    }

    let closeAddGeometricElementForm = (map) => {
        jQuery('#map-container').css('margin-right', '0');
        jQuery('#addGeometricElementContainer').css('display', 'none');
        map.updateSize();
    }

    if (props.allGeometricElementType && props.allCodes && props.parameters && props.listProvinces && props.listCouncils
        && props.listParishes && props.listEntityPopulation && props.listCounties && props.listAllGeometricLayerGroup
    ) {

        let maxExtentParameter = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MAP_MAX_EXTENT") !== -1);

        let initialExtendParameter = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MAP_INITIAL_EXTENT") !== -1);

        let clickPixelTolerance = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("CLICK_PIXEL_TOLERANCE") !== -1);

        let provincesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("PROVINCES_BUFFER") !== -1);

        let councilsBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("COUNCILS_BUFFER") !== -1);

        let parishesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("PARISHES_BUFFER") !== -1);

        let entityPopulationsBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("POPULATION_ENTITIES_BUFFER") !== -1);

        let countiesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("COUNTIES_BUFFER") !== -1);

        let maxExtentIcon = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MAX_EXTENT_CONTROL_ICON") !== -1);

        let enableGeometricElementsQuery = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_QUERY") !== -1);

        let enableGeometricElementsInsertion = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_INSERTION") !== -1);

        let allowConfigureLayersOpacity = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);

        let enableGeographicalViewerScale = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOGRAPHICAL_VIEWER_SCALE") !== -1);

        let allowExportGeographicalViewerToPDF = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ALLOW_EXPORT_GEOGRAPHICAL_VIEWER_TO_PDF") !== -1);

        let otherLayersInfoTimeout = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("OTHER_LAYERS_INFO_TIMEOUT") !== -1);

        let showMousePositionCoordinates = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("SHOW_MOUSE_POSITION_COORDINATES") !== -1);

        let showMousePositionCoordinatesProjection = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("SHOW_MOUSE_POSITION_COORDINATES_PROJECTION") !== -1);

        let showEntityPopulationCenterControl = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("SHOW_ENTITY_POPULATION_CENTER_CONTROL") !== -1);

        let showGeoBookmarkControl = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("SHOW_GEOBOOKMARK_CONTROL") !== -1);

        let enableGeometricElementsRelation = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENTS_RELATION") !== -1);

        if (maxExtentParameter.length > 0) {
            maxExtentParameter = maxExtentParameter[0].value;
            maxExtentParameter = maxExtentParameter.split(",");
            maxExtentParameter = maxExtentParameter.map(Number);
        }

        if (initialExtendParameter.length > 0) {
            initialExtendParameter = initialExtendParameter[0].value;
            initialExtendParameter = initialExtendParameter.split(",");
            initialExtendParameter = initialExtendParameter.map(Number);
        }

        if (clickPixelTolerance.length > 0) {
            clickPixelTolerance = clickPixelTolerance[0].value;
            clickPixelTolerance = Number(clickPixelTolerance);
        }

        if (provincesBuffer.length > 0) {
            provincesBuffer = provincesBuffer[0].value;
            provincesBuffer = Number(provincesBuffer);
        } else {
            provincesBuffer = 50
        }

        if (councilsBuffer.length > 0) {
            councilsBuffer = councilsBuffer[0].value;
            councilsBuffer = Number(councilsBuffer);
        } else {
            councilsBuffer = 50
        }

        if (parishesBuffer.length > 0) {
            parishesBuffer = parishesBuffer[0].value;
            parishesBuffer = Number(parishesBuffer);
        } else {
            parishesBuffer = 50
        }

        if (entityPopulationsBuffer.length > 0) {
            entityPopulationsBuffer = entityPopulationsBuffer[0].value;
            entityPopulationsBuffer = Number(entityPopulationsBuffer);
        } else {
            entityPopulationsBuffer = 100
        }

        if (countiesBuffer.length > 0) {
            countiesBuffer = countiesBuffer[0].value;
            countiesBuffer = Number(countiesBuffer);
        } else {
            countiesBuffer = 50
        }

        if (maxExtentIcon.length > 0 && maxExtentIcon[0].value && maxExtentIcon[0].value !== "") {
            let icon = document.createElement('img');
            icon.src = maxExtentIcon[0].value;
            icon.className = "svg-inline--fa"
            icon.style.width = '1em';
            icon.style.height = '1em';
            maxExtentIcon = icon;
        } else {
            maxExtentIcon = 'E';
        }

        if (enableGeometricElementsQuery.length > 0) {
            enableGeometricElementsQuery = enableGeometricElementsQuery[0].value;
            enableGeometricElementsQuery = (enableGeometricElementsQuery === 'true');
        } else {
            enableGeometricElementsQuery = true;
        }

        if (enableGeometricElementsInsertion.length > 0) {
            enableGeometricElementsInsertion = enableGeometricElementsInsertion[0].value;
            enableGeometricElementsInsertion = (enableGeometricElementsInsertion === 'true');
        } else {
            enableGeometricElementsInsertion = true;
        }

        if (allowConfigureLayersOpacity.length > 0) {
            allowConfigureLayersOpacity = allowConfigureLayersOpacity[0].value;
            allowConfigureLayersOpacity = (allowConfigureLayersOpacity === 'true');
        } else {
            allowConfigureLayersOpacity = false;
        }

        if (enableGeographicalViewerScale.length > 0) {
            enableGeographicalViewerScale = enableGeographicalViewerScale[0].value;
            enableGeographicalViewerScale = (enableGeographicalViewerScale === 'true');
        } else {
            enableGeographicalViewerScale = false;
        }

        if (allowExportGeographicalViewerToPDF.length > 0) {
            allowExportGeographicalViewerToPDF = allowExportGeographicalViewerToPDF[0].value;
            allowExportGeographicalViewerToPDF = (allowExportGeographicalViewerToPDF === 'true');
        } else {
            allowExportGeographicalViewerToPDF = false;
        }

        if (otherLayersInfoTimeout.length > 0) {
            otherLayersInfoTimeout = otherLayersInfoTimeout[0].value;
            otherLayersInfoTimeout = Number(otherLayersInfoTimeout);
        } else {
            otherLayersInfoTimeout = 15000
        }

        if (showMousePositionCoordinates.length > 0) {
            showMousePositionCoordinates = showMousePositionCoordinates[0].value;
            showMousePositionCoordinates = (showMousePositionCoordinates === 'true');
        } else {
            showMousePositionCoordinates = false;
        }

        if (showMousePositionCoordinatesProjection.length > 0) {
            showMousePositionCoordinatesProjection = showMousePositionCoordinatesProjection[0].value || 'EPSG:25829';
        } else {
            showMousePositionCoordinatesProjection = 'EPSG:25829';
        }

        if (showEntityPopulationCenterControl.length > 0) {
            showEntityPopulationCenterControl = showEntityPopulationCenterControl[0].value;
            showEntityPopulationCenterControl = (showEntityPopulationCenterControl === 'true');
        } else {
            showEntityPopulationCenterControl = true;
        }

        if (showGeoBookmarkControl.length > 0) {
            showGeoBookmarkControl = showGeoBookmarkControl[0].value;
            showGeoBookmarkControl = (showGeoBookmarkControl === 'true');
        } else {
            showGeoBookmarkControl = true;
        }

        if (enableGeometricElementsRelation.length > 0) {
            enableGeometricElementsRelation = enableGeometricElementsRelation[0].value;
            enableGeometricElementsRelation = (enableGeometricElementsRelation === 'true') ||
                (props.authenticatedUser && props.authenticatedUser.userRoleDto.code === "ADMIN");
        } else {
            enableGeometricElementsRelation = false;
        }


        return (
            <GeographicalViewer
                openAddGeometricElementForm={openAddGeometricElementForm}
                closeAddGeometricElementForm={closeAddGeometricElementForm}
                maxExtentParameter={maxExtentParameter}
                initialExtendParameter={initialExtendParameter}
                clickPixelTolerance={clickPixelTolerance}
                provincesBuffer={provincesBuffer}
                councilsBuffer={councilsBuffer}
                parishesBuffer={parishesBuffer}
                entityPopulationsBuffer={entityPopulationsBuffer}
                countiesBuffer={countiesBuffer}
                maxExtentIcon={maxExtentIcon}
                otherLayersInfoTimeout={otherLayersInfoTimeout}
                key={props.language}
                // Only show add geometric element button if exists at least one geometric element type and
                // ENABLE_GEOMETRIC_ELEMENT_INSERTION is true or the authenticated user is an administrator
                canAddGeometricElement={props.allGeometricElementType.length > 0 && (enableGeometricElementsInsertion ||
                    (props.authenticatedUser && props.authenticatedUser.userRoleDto.code === "ADMIN"))}
                // Only show query geometric element button if exists at least one geometric element type and
                // ENABLE_GEOMETRIC_ELEMENT_QUERY is true or the authenticated user is an administrator
                canQueryGeometricElement={
                    props.allGeometricElementType.length > 0 && (enableGeometricElementsQuery ||
                        (props.authenticatedUser && props.authenticatedUser.userRoleDto.code === "ADMIN"))
                }
                allowConfigureLayersOpacity={allowConfigureLayersOpacity}
                enableGeographicalViewerScale={enableGeographicalViewerScale}
                allowExportGeographicalViewerToPDF={allowExportGeographicalViewerToPDF}
                showMousePositionCoordinates={showMousePositionCoordinates}
                showMousePositionCoordinatesProjection={showMousePositionCoordinatesProjection}
                showEntityPopulationCenterControl={showEntityPopulationCenterControl}
                showGeoBookmarkControl={showGeoBookmarkControl}
                enableGeometricElementsRelation={enableGeometricElementsRelation}
                style={{
                    width: "100%",
                    height: "82vh"
                }}
            />
        );
    } else {
        return (
            <Modal centered={true} show={true} size={'sm'} dialogClassName="loadingModal">
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border loadingSpinner" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(mapStateToProps)(OpenLayersMap));