import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import * as selectors from '../selectors'
import * as userSelector from '../../users/selectors';
import * as userActions from '../../users/actions'
import * as parameterSelector from '../../configurationParameter/selectors';
import * as attachedFilesSelector from '../../attachedFile/selectors'
import { useDispatch, connect } from 'react-redux';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import * as geometricElementsActions from '../../geometricElements/actions';
import * as actions from '../actions';
import '../../globalstyles.css'
import InternationalizationRender, { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_ELEMENT_TEXT_SINGULAR, PARAMETER_MY_ELEMENTS_TEXT } from './InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Login, SignUp, ChangePassword } from '../../users';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import jQuery from 'jquery';
import { createUseStyles } from 'react-jss';
import { capitalizeFirstLetter, renderLanguageOptions } from '../../common/util';

const mapStateToProps = function (state) {

    return {
        language: selectors.getLanguage(state),
        activeLanguages: selectors.getActiveLanguages(state),
        user: userSelector.getUser(state),
        parameters: parameterSelector.getTotalConfigurationParameters(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        allGeometricElementType: geometricElementsSelector.getTotalGeometricElementType(state),
        allGeneralAttachedFiles: attachedFilesSelector.getTotalGeneralAttachedFiles(state)
    }
}

const useStyles = createUseStyles({
    navbarBackground: {
        backgroundColor: ({ menuBackgroundColorValue }) => [menuBackgroundColorValue, '!important']
    },
    navLinkColor: ({ menuTextColorValue, menuTextColorHoverValue }) => ({
        color: [menuTextColorValue, '!important'],
        '&:hover': {
            color: [menuTextColorHoverValue, '!important']
        }
    }),
    navTogglerIcon: ({ menuTextColorValue }) => ({
        backgroundImage: [`url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23${menuTextColorValue && menuTextColorValue.substring(1)}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`, '!important']
    })
});

const Header = (props) => {

    const dispatch = useDispatch();
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [loginInsertFromNavbar, setLoginInsertFromNavbar] = useState(null);
    const [signUpModalShow, setSignUpModalShow] = useState(false);
    const [passwordModalShow, setPasswordModalShow] = useState(false);
    const [backendErrors, setBackendErrors] = useState(null);
    const [downloadFileErroModalShow, setDownloadFileErrorModalShow] = useState(false);
    const handleOpenLoginModalWindow = (insertFromNavbar) => {
        setBackendErrors(null);
        setLoginModalShow(true);
        setLoginInsertFromNavbar(insertFromNavbar);
    }
    const handleOpenSignUpModalWindow = () => {
        setBackendErrors(null);
        setLoginModalShow(false);
        setSignUpModalShow(true);
    }
    const handleOpenPasswordModalWindow = () => {
        setBackendErrors(null);
        setPasswordModalShow(true)
    }
    let intl = useIntl();

    let appTitle;
    let navbarLogo;
    let navbarLink;
    let canInsertGeometricElements;
    let canQueryGeometricElements;
    let canDownloadAndroidApp;
    let menuBackgroundColor;
    let menuTextColor;
    let menuTextColorHover;

    const [menuBackgroundColorValue, setMenuBackgroundColorValue] = useState(menuBackgroundColor)
    const [menuTextColorValue, setMenuTextColorValue] = useState(menuTextColor)
    const [menuTextColorHoverValue, setMenuTextColorHoverValue] = useState(menuTextColorHover)

    jQuery(function () {
        jQuery('#downloadAPK').tooltip({ trigger: "hover" });
    });

    jQuery(function () {
        jQuery('#downloadAPK').tooltip().click(function () {
            jQuery('#downloadAPK').tooltip("hide");
        });
    });

    const styles = useStyles({ menuBackgroundColorValue, menuTextColorValue, menuTextColorHoverValue });

    // eslint-disable-next-line
    useEffect(() => {
        if (menuBackgroundColorValue !== menuBackgroundColor) {
            setMenuBackgroundColorValue(menuBackgroundColor);
        }
        if (menuTextColorValue !== menuTextColor) {
            setMenuTextColorValue(menuTextColor);
            // This must be done because it does not currently work with react-jss
            let navbarTogglerButton = document.getElementById('navbarTogglerButton');
            if (navbarTogglerButton)
                navbarTogglerButton.style.borderColor = menuTextColor;
        }
        if (menuTextColorHoverValue !== menuTextColorHover) {
            setMenuTextColorHoverValue(menuTextColorHover);
        }
    })

    if (props.parameters && props.allGeometricElementType && props.allCodes && props.allGeneralAttachedFiles) {

        let apkGeneralAttachedFiles = props.allGeneralAttachedFiles.filter(file => file.fileFormat.code === "FILE_FORMAT_APK");

        if (apkGeneralAttachedFiles.length > 1) {
            apkGeneralAttachedFiles.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        }

        appTitle = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("APP_TITLE") !== -1);
        navbarLogo = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("NAVBAR_LOGO") !== -1);
        navbarLink = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("NAVBAR_IMAGE_LINK") !== -1);
        canInsertGeometricElements = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_INSERTION") !== -1);
        canQueryGeometricElements = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_QUERY") !== -1);
        canDownloadAndroidApp = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("ALLOW_DOWNLOAD_APK") !== -1);
        menuBackgroundColor = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MENU_BACKGROUND_COLOR") !== -1);
        menuTextColor = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MENU_TEXT_COLOR") !== -1);
        menuTextColorHover = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MENU_TEXT_COLOR_HOVER") !== -1);
        if (appTitle.length !== 0)
            appTitle = appTitle[0].value;
        if (navbarLogo.length !== 0)
            navbarLogo = navbarLogo[0].value;
        if (navbarLink.length !== 0)
            navbarLink = navbarLink[0].value;
        if (canInsertGeometricElements.length > 0) {
            canInsertGeometricElements = canInsertGeometricElements[0].value;
            canInsertGeometricElements = (canInsertGeometricElements === 'true');
        } else {
            canInsertGeometricElements = true;
        }
        if (canQueryGeometricElements.length > 0) {
            canQueryGeometricElements = canQueryGeometricElements[0].value;
            canQueryGeometricElements = (canQueryGeometricElements === 'true');
        } else {
            canQueryGeometricElements = true;
        }

        if (canDownloadAndroidApp.length > 0 && apkGeneralAttachedFiles.length > 0) {
            canDownloadAndroidApp = canDownloadAndroidApp[0].value;
            canDownloadAndroidApp = (canDownloadAndroidApp === 'true');
        } else {
            canDownloadAndroidApp = false;
        }
        if (menuBackgroundColor.length !== 0)
            menuBackgroundColor = menuBackgroundColor[0].value;
        if (menuTextColor.length !== 0)
            menuTextColor = menuTextColor[0].value;
        if (menuTextColorHover.length !== 0)
            menuTextColorHover = menuTextColorHover[0].value;

        let resetMapCurrentExtent = () => {
            if (props.history.location.pathname !== "/") {
                dispatch(geometricElementsActions.mapCurrentExtent(null))
            }
        }

        return (
            <div>
                <Login
                    modalShow={loginModalShow}
                    insertFromNavBar={loginInsertFromNavbar}
                    backendErrors={backendErrors}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => setLoginModalShow(false)}
                    handleOpenSignUpModalWindow={handleOpenSignUpModalWindow}
                />
                <SignUp
                    modalShow={signUpModalShow}
                    backendErrors={backendErrors}
                    handleAddNewUser={null}
                    setBackendErrors={setBackendErrors}
                    insertFromNavBar={loginInsertFromNavbar}
                    hideModalWindow={() => setSignUpModalShow(false)}
                />
                <ChangePassword
                    modalShow={passwordModalShow}
                    backendErrors={backendErrors}
                    user={props.user}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => setPasswordModalShow(false)}
                />

                {/* Modal download file error */}
                <Modal
                    show={downloadFileErroModalShow}
                    onHide={() => setDownloadFileErrorModalShow(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="project.attachedFiles.error.cannotDownloadFileHeader" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="project.attachedFiles.error.cannotDownloadFileBody" />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => setDownloadFileErrorModalShow(false)}>
                            <FormattedMessage id="project.common.close" />
                        </button>
                    </Modal.Footer>
                </Modal>

                <nav className={`navbar navbar-expand-lg fixed-top navbar-dark bg-dark ${styles.navbarBackground}`}>

                    {navbarLink && navbarLink !== "" ?
                        <a className="navbar-brand" href={navbarLink}>
                            {navbarLogo && navbarLogo !== "" ?
                                <img src={`${navbarLogo}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                                :
                                <span className={styles.navLinkColor}>{appTitle}</span>
                            }
                        </a>
                        :
                        <Link className="navbar-brand" to="/"
                            onClick={() => {
                                dispatch(geometricElementsActions.mapCurrentExtent(null));
                            }}
                        >
                            {navbarLogo && navbarLogo !== "" ?
                                <img src={`${navbarLogo}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                                :
                                <span className={styles.navLinkColor}>{appTitle}</span>
                            }

                        </Link>
                    }

                    <button id="navbarTogglerButton" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapseContent"
                        aria-controls="navbarCollapseContent" aria-expanded="false" aria-label="Toggle navigation"
                    >
                        <span className={`navbar-toggler-icon ${styles.navTogglerIcon}`}></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarCollapseContent">

                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item" id="geographicaViewerButton">
                                <Link className={`nav-link ${styles.navLinkColor}`} to="/"
                                    onClick={() => {
                                        dispatch(geometricElementsActions.mapCurrentExtent(null));
                                    }}
                                >
                                    <FormattedMessage id="project.app.Header.viewer" />
                                </Link>
                            </li>

                            {/* Only administrators can see elements if canQueryGeometricElements is false */}
                            {props.allGeometricElementType.length > 0 && (canQueryGeometricElements ||
                                (props.user && props.user.userRoleDto.code === "ADMIN")) ?
                                <li className="nav-item dropdown">
                                    <a className={`nav-link ${styles.navLinkColor} dropdown-toggle`} href="/" data-toggle="dropdown" id="geometricElementsByType">
                                        <FormattedMessage id="project.app.Header.geometricElements" values={{
                                            plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                        }} />
                                    </a>
                                    <div className="dropdown-menu">
                                        {props.allGeometricElementType.map((geometricElement, index) =>
                                            <Link className="dropdown-item"
                                                to={"/geometric_elements/" + geometricElement.id}
                                                id={"elementType" + index}
                                                key={geometricElement.id}
                                            >
                                                <InternationalizationRender
                                                    locale={props.language}
                                                    value={geometricElement.code}
                                                    listInternationalization={props.allCodes}
                                                />
                                            </Link>
                                        )}
                                    </div>
                                </li>
                                : ""
                            }

                            {props.allGeometricElementType.length > 0 && props.user && (canQueryGeometricElements ||
                                (props.user && props.user.userRoleDto.code === "ADMIN")) ?
                                <li className="nav-item dropdown">
                                    <a className={`nav-link ${styles.navLinkColor} dropdown-toggle`} href="/" data-toggle="dropdown" id="myGeometricElements">
                                        <FormattedMessage id="project.app.Header.myContributions" values={{
                                            myElements: capitalizeFirstLetter(getInternationalization(props.language, PARAMETER_MY_ELEMENTS_TEXT, props.allCodes, props.activeLanguages))
                                        }} />
                                    </a>
                                    <div className="dropdown-menu">
                                        {props.allGeometricElementType.map((geometricElement, index) =>
                                            <Link className="dropdown-item"
                                                to={"/geometric_elements/" + geometricElement.id + "/myElements"}
                                                key={geometricElement.id}
                                                id={"myElementsFromType" + index}
                                            >
                                                <InternationalizationRender
                                                    locale={props.language}
                                                    value={geometricElement.code}
                                                    listInternationalization={props.allCodes}
                                                />
                                            </Link>
                                        )}
                                    </div>
                                </li>
                                : ""}

                            {/* Only administrators can insert if canInsertGeometricElements is false */}
                            {props.allGeometricElementType.length > 0 && (canInsertGeometricElements ||
                                (props.user && props.user.userRoleDto.code === "ADMIN")) ?
                                <li className="nav-item dropdown">
                                    <a className={`nav-link ${styles.navLinkColor} dropdown-toggle`} href="/" data-toggle="dropdown" id="addGeometricElement"
                                        onClick={() => {
                                            resetMapCurrentExtent();
                                        }}
                                    >
                                        <FormattedMessage id="project.app.Header.addGeometricElement" values={{
                                            singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                        }}
                                        />
                                    </a>
                                    <div className="dropdown-menu">
                                        {props.allGeometricElementType.map((geometricElement) =>
                                            geometricElement.geometryType === "ANY_GEOMETRY" ?
                                                <div key={geometricElement.id + "Geometry"}>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-Point"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        key={geometricElement.id + "-Point"}
                                                        id={geometricElement.id + '-Point'}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.Point" />
                                                    </span>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        key={geometricElement.id + '-LineString'}
                                                        id={geometricElement.id + '-LineString'}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-LineString"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.LineString" />
                                                    </span>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        key={geometricElement.id + "-Polygon"}
                                                        id={geometricElement.id + '-Polygon'}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-Polygon"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.Polygon" />
                                                    </span>
                                                </div>
                                                : geometricElement.geometryType === "POINT_POLYGON" ?
                                                    <div key={geometricElement.id + "Geometry"}>
                                                        <span className="dropdown-item"
                                                            style={{ cursor: 'pointer' }}
                                                            key={geometricElement.id + "-Point"}
                                                            id={geometricElement.id + '-Point'}
                                                            onClick={() => {
                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                if (!props.user) {
                                                                    handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                } else {
                                                                    props.history.push({
                                                                        pathname: "/insertFromNavBar",
                                                                        state: {
                                                                            insertFromNavBar: geometricElement.id + "-Point"
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <InternationalizationRender
                                                                locale={props.language}
                                                                value={geometricElement.code}
                                                                listInternationalization={props.allCodes}
                                                            />
                                                            &nbsp;-&nbsp;
                                                            <FormattedMessage id="project.elements.geom.Point" />
                                                        </span>
                                                        <span className="dropdown-item"
                                                            style={{ cursor: 'pointer' }}
                                                            key={geometricElement.id + "-Polygon"}
                                                            id={geometricElement.id + '-Polygon'}
                                                            onClick={() => {
                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                if (!props.user) {
                                                                    handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                } else {
                                                                    props.history.push({
                                                                        pathname: "/insertFromNavBar",
                                                                        state: {
                                                                            insertFromNavBar: geometricElement.id + "-Polygon"
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <InternationalizationRender
                                                                locale={props.language}
                                                                value={geometricElement.code}
                                                                listInternationalization={props.allCodes}
                                                            />
                                                            &nbsp;-&nbsp;
                                                            <FormattedMessage id="project.elements.geom.Polygon" />
                                                        </span>
                                                    </div> :
                                                    geometricElement.geometryType === "POINT_LINE" ?
                                                        <div key={geometricElement.id + "Geometry"}>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                key={geometricElement.id + "-Point"}
                                                                id={geometricElement.id + '-Point'}
                                                                onClick={() => {
                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                    if (!props.user) {
                                                                        handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                    } else {
                                                                        props.history.push({
                                                                            pathname: "/insertFromNavBar",
                                                                            state: {
                                                                                insertFromNavBar: geometricElement.id + "-Point"
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <InternationalizationRender
                                                                    locale={props.language}
                                                                    value={geometricElement.code}
                                                                    listInternationalization={props.allCodes}
                                                                />
                                                                &nbsp;-&nbsp;
                                                                <FormattedMessage id="project.elements.geom.Point" />
                                                            </span>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                key={geometricElement.id + '-LineString'}
                                                                id={geometricElement.id + '-LineString'}
                                                                onClick={() => {
                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                    if (!props.user) {
                                                                        handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                    } else {
                                                                        props.history.push({
                                                                            pathname: "/insertFromNavBar",
                                                                            state: {
                                                                                insertFromNavBar: geometricElement.id + "-LineString"
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <InternationalizationRender
                                                                    locale={props.language}
                                                                    value={geometricElement.code}
                                                                    listInternationalization={props.allCodes}
                                                                />
                                                                &nbsp;-&nbsp;
                                                                <FormattedMessage id="project.elements.geom.LineString" />
                                                            </span>
                                                        </div> :
                                                        geometricElement.geometryType === "LINE_POLYGON" ?
                                                            <div key={geometricElement.id + "Geometry"}>
                                                                <span className="dropdown-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={geometricElement.id + '-LineString'}
                                                                    id={geometricElement.id + '-LineString'}
                                                                    onClick={() => {
                                                                        dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                        if (!props.user) {
                                                                            handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                        } else {
                                                                            props.history.push({
                                                                                pathname: "/insertFromNavBar",
                                                                                state: {
                                                                                    insertFromNavBar: geometricElement.id + "-LineString"
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <InternationalizationRender
                                                                        locale={props.language}
                                                                        value={geometricElement.code}
                                                                        listInternationalization={props.allCodes}
                                                                    />
                                                                    &nbsp;-&nbsp;
                                                                    <FormattedMessage id="project.elements.geom.LineString" />
                                                                </span>
                                                                <span className="dropdown-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={geometricElement.id + "-Polygon"}
                                                                    id={geometricElement.id + '-Polygon'}
                                                                    onClick={() => {
                                                                        dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                        if (!props.user) {
                                                                            handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                        } else {
                                                                            props.history.push({
                                                                                pathname: "/insertFromNavBar",
                                                                                state: {
                                                                                    insertFromNavBar: geometricElement.id + "-Polygon"
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <InternationalizationRender
                                                                        locale={props.language}
                                                                        value={geometricElement.code}
                                                                        listInternationalization={props.allCodes}
                                                                    />
                                                                    &nbsp;-&nbsp;
                                                                    <FormattedMessage id="project.elements.geom.Polygon" />
                                                                </span>
                                                            </div> : geometricElement.geometryType === "POINT" ?
                                                                <div key={geometricElement.id + "Geometry"}>
                                                                    <span className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        key={geometricElement.id + "-Point"}
                                                                        id={geometricElement.id + '-Point'}
                                                                        onClick={() => {
                                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                            if (!props.user) {
                                                                                handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                            } else {
                                                                                props.history.push({
                                                                                    pathname: "/insertFromNavBar",
                                                                                    state: {
                                                                                        insertFromNavBar: geometricElement.id + "-Point"
                                                                                    }
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <InternationalizationRender
                                                                            locale={props.language}
                                                                            value={geometricElement.code}
                                                                            listInternationalization={props.allCodes}
                                                                        />
                                                                        &nbsp;-&nbsp;
                                                                        <FormattedMessage id="project.elements.geom.Point" />
                                                                    </span>
                                                                </div> :
                                                                geometricElement.geometryType === "LINE" ?
                                                                    <div key={geometricElement.id + "Geometry"}>
                                                                        <span className="dropdown-item"
                                                                            style={{ cursor: 'pointer' }}
                                                                            key={geometricElement.id + '-LineString'}
                                                                            id={geometricElement.id + '-LineString'}
                                                                            onClick={() => {
                                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                                if (!props.user) {
                                                                                    handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                                } else {
                                                                                    props.history.push({
                                                                                        pathname: "/insertFromNavBar",
                                                                                        state: {
                                                                                            insertFromNavBar: geometricElement.id + "-LineString"
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            <InternationalizationRender
                                                                                locale={props.language}
                                                                                value={geometricElement.code}
                                                                                listInternationalization={props.allCodes}
                                                                            />
                                                                            &nbsp;-&nbsp;
                                                                            <FormattedMessage id="project.elements.geom.LineString" />
                                                                        </span>
                                                                    </div> :
                                                                    geometricElement.geometryType === "POLYGON" ?
                                                                        <div key={geometricElement.id + "Geometry"}>
                                                                            <span className="dropdown-item"
                                                                                style={{ cursor: 'pointer' }}
                                                                                key={geometricElement.id + "-Polygon"}
                                                                                id={geometricElement.id + '-Polygon'}
                                                                                onClick={() => {
                                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                                    if (!props.user) {
                                                                                        handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                                    } else {
                                                                                        props.history.push({
                                                                                            pathname: "/insertFromNavBar",
                                                                                            state: {
                                                                                                insertFromNavBar: geometricElement.id + "-Polygon"
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <InternationalizationRender
                                                                                    locale={props.language}
                                                                                    value={geometricElement.code}
                                                                                    listInternationalization={props.allCodes}
                                                                                />
                                                                                &nbsp;-&nbsp;
                                                                                <FormattedMessage id="project.elements.geom.Polygon" />
                                                                            </span>
                                                                        </div> : <div></div>
                                        )
                                        }
                                    </div>
                                </li>
                                : ""
                            }

                            {props.user ? props.user.userRoleDto.code === "ADMIN" ?
                                <li className="nav-item dropdown">
                                    <a className={`nav-link ${styles.navLinkColor} dropdown-toggle`} href="/" data-toggle="dropdown" id="adminDropdown">
                                        <FormattedMessage id="project.app.Header.admin" />
                                    </a>
                                    <div className="dropdown-menu">
                                        <Link className="dropdown-item" to={"/admin/users"} id="adminUsers">
                                            <FormattedMessage id="project.app.Header.admin.users" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/elementType"} id="adminGeometricElementType">
                                            <FormattedMessage id="project.app.Header.admin.elementType" values={{
                                                plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                            }} />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/geometricElementTypeStyles"} id="adminStyles">
                                            <FormattedMessage id="project.app.Header.admin.geometricElementStyles" values={{
                                                plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                            }} />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/attributeType"} id="adminAttributeType">
                                            <FormattedMessage id="project.app.Header.admin.attributeType" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/configurationParameter"} id="adminConfigurationParameter">
                                            <FormattedMessage id="project.app.Header.admin.configurationParameters" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/layerGroup"} id="adminLayerGroup">
                                            <FormattedMessage id="project.app.Header.admin.layerGroup" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/layers"} id="adminLayers">
                                            <FormattedMessage id="project.app.Header.admin.layer" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/generalAttachedFiles"} id="adminAttachedFile">
                                            <FormattedMessage id="project.app.Header.admin.attachedFile" />
                                        </Link>
                                    </div>
                                </li>
                                : "" : ""}
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            {props.user ?
                                <li className="nav-item dropdown">
                                    <a className={`nav-link ${styles.navLinkColor} dropdown-toggle`} href="/" data-toggle="dropdown" id="userdropdown">
                                        <FontAwesomeIcon icon={faUser} />
                                        &nbsp;
                                        {props.user.login}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <span className="dropdown-item"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                handleOpenPasswordModalWindow();
                                            }}
                                            id="myPasswordButton"
                                        >
                                            <FormattedMessage id="project.app.Header.password" />
                                        </span>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to='/users/logout' id="logoutButton">
                                            <FormattedMessage id="project.app.Header.logout" />
                                        </Link>
                                    </div>
                                </li>
                                :
                                <li className="nav-item">
                                    <span className={`nav-link ${styles.navLinkColor}`}
                                        style={{ cursor: 'pointer' }}
                                        id="loginbutton"
                                        onClick={() =>
                                            handleOpenLoginModalWindow(null)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faUser} />
                                        &nbsp;
                                        <FormattedMessage id="project.app.Header.login" />
                                    </span>
                                </li>
                            }
                            <li className="nav-item mr-1">
                                <select className="custom-select" 
                                id="languageSelector" 
                                data-width="fit"
                                onChange={(event) => {
                                    let value = event.target.value;
                                    dispatch(actions.language(value.split("-")[0]));
                                    if (props.user) {
                                        props.user.locale = value;
                                        dispatch(userActions.changeLanguage(props.user));
                                    }
                                }}>
                                    {renderLanguageOptions(props.activeLanguages, props.language, props.allCodes)}
                                </select>
                            </li>

                            {canDownloadAndroidApp ?
                                <li className="nav-item">
                                    <button className="btn btn-success"
                                        id="downloadAPK"
                                        onClick={() => {
                                            jQuery('#downloadAPK').css('cursor', 'wait');
                                            jQuery('#downloadAPK').attr('disabled', true);
                                            let file = apkGeneralAttachedFiles[0];
                                            axios({
                                                url: file.fileUrl,
                                                method: 'GET',
                                                responseType: 'blob'
                                            }).then((response) => {
                                                jQuery('#downloadAPK').css('cursor', 'pointer');
                                                jQuery('#downloadAPK').removeAttr('disabled');
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', file.originalFilename);
                                                document.body.appendChild(link);
                                                link.click();
                                            }).catch(error => {
                                                jQuery('#downloadAPK').css('cursor', 'pointer');
                                                jQuery('#downloadAPK').removeAttr('disabled');
                                                setDownloadFileErrorModalShow(true);
                                            });
                                        }}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        data-html={true}
                                        title={intl.formatMessage({ id: "project.app.Header.downloadAPK" })}
                                        data-original-title={intl.formatMessage({ id: "project.app.Header.downloadAPK" })}
                                    >
                                        <i className="fab fa-android"></i>
                                    </button>
                                </li>
                                :
                                ""
                            }
                        </ul>
                    </div>

                </nav>

            </div>
        );
    } else {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
                <Link className="navbar-brand" to="/">
                    <img src={`${navbarLogo}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapseContent"
                    aria-controls="navbarCollapseContent" aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span className={`navbar-toggler-icon ${styles.navTogglerIcon}`}></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapseContent">

                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item" id="geographicaViewerButton">
                            <Link className="nav-link" to="/">
                                <FormattedMessage id="project.app.Header.viewer" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
};

export default withRouter(connect(mapStateToProps)(Header));