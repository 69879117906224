import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors'
import { Modal, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { FormattedMessage } from 'react-intl'
import * as userSelectors from '../../users/selectors';
import { withRouter } from 'react-router-dom';
import { getInternationalization } from '../../app/components/InternationalizationRender';
import { chunk } from 'lodash'
import * as locationActions from '../../location/actions';
import * as locationSelectors from '../../location/selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { sortGeometricElementTypeAttributeTypeComparator } from '../../common/util';

const RELATE_ELEMENTS_TO_PARISHES = "RELATE_ELEMENTS_TO_PARISHES";

const mapStateToProps = function (state) {
    return ({
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        authenticatedUser: userSelectors.getUser(state),
        geometricElements: selectors.getGeometricElementsByElementType(state),
        provinces: locationSelectors.getProvinces(state),
        municipalities: locationSelectors.getMunicipalitiesByProvince(state),
        parishes: locationSelectors.getParishesByMunicipality(state),
        configurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    });
}

const renderAttributeTypes = (language, allCodes, listAttributes, activeLanguages) => {
    let attributesSplit = chunk(listAttributes, 2);
    return (
        attributesSplit.map((attributesPair) =>
            <div className="row form-group">
                {attributesPair.map(attributeType =>
                    attributeType.attributeType.dataType === "MULTISELECT" || attributeType.attributeType.dataType === "SELECT" ?
                        <div className="col-6">
                            <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                            </label>
                            <Field as="select" id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                className="form-control"
                            >
                                <FormattedMessage id="project.common.selectOneOption">
                                    {(msg) => <option value="">{msg}</option>}
                                </FormattedMessage>
                                {attributeType.attributeType.valueGroupCode.listCode.map(code =>
                                    <option value={code.code} key={code.code}>
                                        {getInternationalization(language, code.code, allCodes, activeLanguages)}
                                    </option>
                                )}
                            </Field>
                        </div>
                        : attributeType.attributeType.dataType === "TEXT" ?
                            <div className="col-6">
                                <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                    {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                                </label>
                                <Field as="input" type="text"
                                    id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                    className="form-control"
                                />
                            </div>
                            :
                            attributeType.attributeType.dataType === "DATE" ?
                                <div className="col-6">
                                    <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                        {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                                    </label>
                                    <Field as="input" type="date"
                                        id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                        className="form-control"
                                    />
                                </div>
                                :
                                attributeType.attributeType.dataType === "DATE_TIME" ?
                                    <div className="col-6">
                                        <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                            {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                                        </label>
                                        <Field as="input" type="datetime-local"
                                            id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                            className="form-control"
                                        />
                                    </div>
                                    :
                                    attributeType.attributeType.dataType === "NUMBER" ?
                                        <div className="col-6">
                                            <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                                {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                                            </label>
                                            <Field as="input" type="number"
                                                id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                                className="form-control"
                                            />
                                        </div>
                                        :
                                        attributeType.attributeType.dataType === "FLOAT" ?
                                            <div className="col-6">
                                                <label htmlFor={attributeType.attributeType.code.code} className="font-weight-bold">
                                                    {getInternationalization(language, attributeType.attributeType.code.code, allCodes, activeLanguages)}
                                                </label>
                                                <Field as="input" type="number" step="0.05"
                                                    id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                                    className="form-control"
                                                />
                                            </div>
                                            :
                                            ""
                )}
            </div>)
    )
}

const GeometricElementsByGeometricElementTypeSearch = ({ modalShow, geometricElements, authenticatedUser, currentPage,
    currentSize, currentSortOrder, currentSortField, hideModalWindow, handleSubmit, listAttributes, setLocationFiltersName,
    moveToFirstPage, ...props }) => {

    let relateElementsToParishesParameter;

    if (props.configurationParameters) {
        relateElementsToParishesParameter = props.configurationParameters.filter(configurationParameter =>
            configurationParameter.code === RELATE_ELEMENTS_TO_PARISHES)[0];
    }

    const renderAllOtherFields = (values, setFieldValue) => {

        let fields = [];

        if (authenticatedUser) {
            if (authenticatedUser.userRoleDto.code === "USER") {
                if (props.history.location.pathname.includes("myElements")) {
                    fields.push("isPublic", "isReviewed");
                } else {
                    fields.push("isReviewed");
                }
            } else {
                if (props.history.location.pathname.includes("myElements")) {
                    fields.push("isPublic", "isReviewed", "internalComments");
                } else {
                    fields.push("isPublic", "isReviewed", "internalComments", "owner");
                }
            }
        }

        let fieldSplit = chunk(fields, 2);
        return (
            fieldSplit.map(fieldsPair =>
                <div className="row form-group">
                    {fieldsPair.map(field =>
                        field === "isPublic" ?
                            <div className="col-6">
                                <label htmlFor="isPublic" className="font-weight-bold">
                                    <FormattedMessage id="project.elements.isPublic" />
                                </label>
                                <Field as="select" id="isPublic" name="isPublic" className="form-control">
                                    <FormattedMessage id="project.common.selectOneOption">
                                        {(msg) => <option value="">{msg}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="project.common.yes">
                                        {(msg) => <option value="true">{msg}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="project.common.no">
                                        {(msg) => <option value="false">{msg}</option>}
                                    </FormattedMessage>
                                </Field>
                            </div>
                            : field === "isReviewed" ?
                                <div className="col-6">
                                    <label htmlFor="isReviewed" className="font-weight-bold">
                                        <FormattedMessage id="project.elements.isReviewed" />
                                    </label>
                                    <Field as="select" id="isReviewed" name="isReviewed" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        <FormattedMessage id="project.common.yes">
                                            {(msg) => <option value="true">{msg}</option>}
                                        </FormattedMessage>
                                        <FormattedMessage id="project.common.no">
                                            {(msg) => <option value="false">{msg}</option>}
                                        </FormattedMessage>
                                    </Field>
                                </div>
                                : field === "internalComments" ?
                                    <div className="col-6">
                                        <label htmlFor="internalComments" className="font-weight-bold">
                                            <FormattedMessage id="project.elements.internalComments" />
                                        </label>
                                        <Field as="textarea" id="internalComments" name="internalComments" className="form-control" />
                                    </div>
                                    : field === "owner" ?
                                        <div className="col-6">
                                            <label htmlFor="owner" className="font-weight-bold">
                                                <FormattedMessage id="project.elements.owner" />
                                            </label>
                                            <Field id="owner" name="owner" className="form-control" />
                                        </div>
                                        : ""
                    )}
                </div>
            )
        )
    }

    if (modalShow && geometricElements) {

        let sortedListAttributes = listAttributes.sort(sortGeometricElementTypeAttributeTypeComparator);

        let initialValues = {
            id: geometricElements.geometricElementId ? geometricElements.geometricElementId : '',
            description: geometricElements.description ? geometricElements.description : '',
            beforeCreationDate: geometricElements.beforeCreationDate ? geometricElements.beforeCreationDate : '',
            laterCreationDate: geometricElements.laterCreationDate ? geometricElements.laterCreationDate : '',
            beforeModificationDate: geometricElements.beforeModificationDate ? geometricElements.beforeModificationDate : '',
            laterModificationDate: geometricElements.laterModificationDate ? geometricElements.laterModificationDate : '',
            isPublic: geometricElements.isPublic ? geometricElements.isPublic : '',
            isReviewed: geometricElements.isReviewed ? geometricElements.isReviewed : '',
            internalComments: geometricElements.internalComments ? geometricElements.internalComments : '',
            owner: geometricElements.ownerLogin ? geometricElements.ownerLogin : ''
        }

        if (relateElementsToParishesParameter.value === "true") {
            initialValues.province = geometricElements.province ? geometricElements.province : '';
            initialValues.municipality = geometricElements.municipality ? geometricElements.municipality : '';
            initialValues.parish = geometricElements.parish ? geometricElements.parish : '';
        }

        sortedListAttributes.forEach(attribute => {
            initialValues[attribute.attributeType.code.code] = geometricElements.attributes ? geometricElements.attributes[attribute.attributeType.id] ?
                geometricElements.attributes[attribute.attributeType.id] : "" : ""
        });

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    let attributes = {}
                    sortedListAttributes.forEach(attribute => {
                        if (values[attribute.attributeType.code.code] !== null && values[attribute.attributeType.code.code] !== "")
                            attributes[attribute.attributeType.id] = values[attribute.attributeType.code.code];
                    });

                    let setAllLocationFiltersName = () => {
                        setLocationFiltersName(
                            values.province && values.province !== "" ?
                                props.provinces.filter(province => province.provinceCode === values.province)[0].name : "",
                            values.municipality && values.municipality !== "" ?
                                props.municipalities.filter(municipality => municipality.ineCode === values.municipality)[0].name : "",
                            values.parish && values.parish !== "" ?
                                props.parishes.filter(parish => parish.parishCode === values.parish)[0].name : ""
                        );
                    }

                    if (authenticatedUser) {
                        if (authenticatedUser.userRoleDto.code === "ADMIN") {
                            if (props.history.location.pathname.includes("myElements")) {
                                props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    geometricElements.id, currentPage - 1, currentSize, currentSortField, currentSortOrder,
                                    values.isPublic, authenticatedUser.id, values.id, values.description, values.beforeCreationDate,
                                    values.laterCreationDate, values.beforeModificationDate, values.laterModificationDate,
                                    values.isReviewed, values.internalComments, null, attributes, values.province,
                                    values.municipality, values.parish, () => {
                                        setAllLocationFiltersName();
                                        hideModalWindow();
                                        moveToFirstPage();
                                    }))
                            } else {
                                props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    geometricElements.id, currentPage - 1, currentSize, currentSortField, currentSortOrder,
                                    values.isPublic, null, values.id, values.description, values.beforeCreationDate,
                                    values.laterCreationDate, values.beforeModificationDate, values.laterModificationDate,
                                    values.isReviewed, values.internalComments, values.owner, attributes, values.province,
                                    values.municipality, values.parish, () => {
                                        setAllLocationFiltersName();
                                        hideModalWindow();
                                        moveToFirstPage();
                                    }))
                            }
                        } else {
                            if (props.history.location.pathname.includes("myElements")) {
                                props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    geometricElements.id, currentPage - 1, currentSize, currentSortField, currentSortOrder,
                                    values.isPublic, authenticatedUser.id, values.id, values.description, values.beforeCreationDate,
                                    values.laterCreationDate, values.beforeModificationDate, values.laterModificationDate,
                                    values.isReviewed, attributes, values.province,
                                    values.municipality, values.parish, () => {
                                        setAllLocationFiltersName();
                                        hideModalWindow();
                                        moveToFirstPage();
                                    }))
                            } else {
                                props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    geometricElements.id, currentPage - 1, currentSize, currentSortField, currentSortOrder,
                                    geometricElements.isPublic, null, values.id, values.description, values.beforeCreationDate,
                                    values.laterCreationDate, values.beforeModificationDate, values.laterModificationDate,
                                    values.isReviewed, attributes, values.province,
                                    values.municipality, values.parish, () => {
                                        setAllLocationFiltersName();
                                        hideModalWindow();
                                        moveToFirstPage();
                                    }))
                            }
                        }
                    } else {
                        props.dispatch(actions.findPublicGeometricElementsByElementTypeId(
                            geometricElements.id, currentPage - 1, currentSize, currentSortField, currentSortOrder, values.id,
                            values.description, values.beforeCreationDate, values.laterCreationDate, values.beforeModificationDate,
                            values.laterModificationDate, attributes, values.province,
                            values.municipality, values.parish, () => {
                                setAllLocationFiltersName();
                                hideModalWindow();
                                moveToFirstPage();
                            }))
                    }

                    setSubmitting(false);
                }}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({ resetForm, values, setFieldValue }) =>
                    <Modal show={modalShow} size="lg"
                        onHide={() => {
                            hideModalWindow();
                            resetForm();
                        }}
                        scrollable={true}
                    >
                        <Modal.Header closeButton>
                            <ModalTitle>
                                <FormattedMessage id="project.app.Body.search" />
                            </ModalTitle>
                        </Modal.Header>
                        <ModalBody>
                            <Form id="searchGeometricElementsForm">
                                <div className="row form-group">
                                    <div className="col-6">
                                        <label htmlFor="id" className="font-weight-bold">
                                            Id
                                        </label>
                                        <Field type="number" id="id" name="id" className="form-control" />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="description" className="font-weight-bold">
                                            <FormattedMessage id="project.elements.description" />
                                        </label>
                                        <Field as="textarea" id="description" name="description" className="form-control" />
                                    </div>
                                </div>

                                {sortedListAttributes.length > 0 ?
                                    <div className="card" style={{ marginBottom: "1rem" }}>
                                        <h5 className="card-header">
                                            <FormattedMessage id="project.common.attributes" />
                                        </h5>
                                        <div className="card-body">
                                            {renderAttributeTypes(props.language, props.allCodes, sortedListAttributes, props.activeLanguages)}
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                                {relateElementsToParishesParameter && relateElementsToParishesParameter.value === "true" ?
                                    <div className="card" style={{ marginBottom: "1rem" }}>
                                        <h5 className="card-header">
                                            <FormattedMessage id="project.common.location" />
                                        </h5>
                                        <div className="card-body">
                                            <div className="row form-group">
                                                <div className="col-6">
                                                    <label htmlFor="province" className="font-weight-bold">
                                                        <FormattedMessage id="project.common.location.province" />
                                                    </label>
                                                    <Field as="select" id="province " name="province" className="form-control"
                                                        value={values.province}
                                                        onChange={async e => {
                                                            const { value } = e.target;
                                                            if (value !== "") {
                                                                props.dispatch(locationActions.findMunicipalitiesByProvince(value));
                                                            }
                                                            setFieldValue("province", value);
                                                            setFieldValue("municipality", "");
                                                            setFieldValue("parish", "");
                                                        }}
                                                    >
                                                        <FormattedMessage id="project.common.selectOneOption">
                                                            {(msg) => <option value="">{msg}</option>}
                                                        </FormattedMessage>
                                                        {props.provinces.map(province =>
                                                            <option value={province.provinceCode} key={province.id + province.provinceCode}>
                                                                {province.name}
                                                            </option>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="municipality" className="font-weight-bold">
                                                        <FormattedMessage id="project.common.location.municipality" />
                                                    </label>
                                                    <Field as="select" id="municipality " name="municipality" className="form-control"
                                                        disabled={values.province === ""}
                                                        onChange={async e => {
                                                            const { value } = e.target;
                                                            if (value !== "") {
                                                                props.dispatch(locationActions.findParishesByMunicipality(values.province, value));
                                                            }
                                                            setFieldValue("municipality", value);
                                                            setFieldValue("parish", "");
                                                        }}
                                                    >
                                                        <FormattedMessage id="project.common.selectOneOption">
                                                            {(msg) => <option value="">{msg}</option>}
                                                        </FormattedMessage>
                                                        {props.municipalities ? props.municipalities.map(municipality =>
                                                            <option value={municipality.ineCode}
                                                                key={municipality.id + municipality.ineCode}
                                                            >
                                                                {municipality.name}
                                                            </option>
                                                        ) : ""}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-6">
                                                    <label htmlFor="parish" className="font-weight-bold">
                                                        <FormattedMessage id="project.common.location.parish" />
                                                    </label>
                                                    <Field as="select" id="parish " name="parish" className="form-control"
                                                        disabled={values.municipality === "" || values.province === ""}
                                                    >
                                                        <FormattedMessage id="project.common.selectOneOption">
                                                            {(msg) => <option value="">{msg}</option>}
                                                        </FormattedMessage>
                                                        {props.parishes ? props.parishes.map(parish =>
                                                            <option value={parish.parishCode}
                                                                key={parish.id + parish.parishCode}
                                                            >
                                                                {parish.name}
                                                            </option>
                                                        ) : ""}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }

                                <div className="card" style={{ marginBottom: "1rem" }}>
                                    <h5 className="card-header">
                                        <FormattedMessage id="project.common.dates" />
                                    </h5>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-6">
                                                <label htmlFor="laterCreationDate" className="font-weight-bold">
                                                    <FormattedMessage id="project.elements.moreCreationDate" />
                                                </label>
                                                <Field type="date" id="laterCreationDate" name="laterCreationDate" className="form-control" />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="beforeCreationDate" className="font-weight-bold">
                                                    <FormattedMessage id="project.elements.lessCreationDate" />
                                                </label>
                                                <Field type="date" id="beforeCreationDate" name="beforeCreationDate" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-6">
                                                <label htmlFor="laterModificationDate" className="font-weight-bold">
                                                    <FormattedMessage id="project.elements.moreModificationDate" />
                                                </label>
                                                <Field type="date" id="laterModificationDate" name="laterModificationDate" className="form-control" />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="beforeModificationDate" className="font-weight-bold">
                                                    <FormattedMessage id="project.elements.lessModificationDate" />
                                                </label>
                                                <Field type="date" id="beforeModificationDate" name="beforeModificationDate" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {renderAllOtherFields(values, setFieldValue)}

                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <button id="searchGeometricElementSubmit" type="submit" className="btn btn-primary"
                                form="searchGeometricElementsForm"
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="searchGeometricElementCancel" type="reset" className="btn btn-danger"
                                onClick={() => {
                                    resetForm();
                                    hideModalWindow();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </ModalFooter>
                    </Modal>
                }
            </Formik>
        )
    }

    return null;
}

export default connect(mapStateToProps)(withRouter(GeometricElementsByGeometricElementTypeSearch));