import React from 'react';
import * as selectors from '../selectors';
import * as userSelector from '../../users/selectors';
import * as appSelectors from '../../app/selectors';
import * as actions from '../actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InternationalizationRender, { getInternationalization, hasHelp } from '../../app/components/InternationalizationRender';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faChevronCircleDown, faChevronCircleUp, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { DeleteDialog } from '../../common';
import AddModifyAttributeType from './AddModifyAttributeType';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        user: userSelector.getUser(state),
        allCodes: selectors.getAllCodes(state),
        totalAttributeType: selectors.getTotalAttributeType(state)
    }
}

class AllAttributeType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandedRows: [],
            sizePerPage: 10,
            backendErrors: null,
            deleteAttributeTypeDialogShow: false,
            deleteAttributeTypeId: "",
            deleteAttributeTypeCode: "",
            addModifyAttributeTypeModalShow: false,
            attributeTypeToModify: null,
            totalAttributeType: null
        }
    }

    componentDidMount() {
        this.setState({ totalAttributeType: this.props.totalAttributeType });
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });

        if (!this.state.totalAttributeType && this.props.totalAttributeType) {
            this.setState({ totalAttributeType: this.props.totalAttributeType });
        }
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        jQuery(function () {
            jQuery('.actionButton').tooltip({ trigger: "hover" });
        });

        jQuery(function () {
            jQuery('.actionButton').tooltip().click(function () {
                jQuery('.actionButton').tooltip("hide");
            });
        });

        jQuery(function () {
            jQuery('.helpTooltip').tooltip({
                // html: true,
                placement: 'left',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['left'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });

            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        let handleBtnClick = (row) => {

            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        let clearAllFilters = (filterById) => {
            let { idFilter, codeFilter, nameFilter, descriptionFilter, dataTypeFilter, helpFilter } = this.state.filters;
            if (filterById) {
                idFilter(`${filterById}`)
            } else {
                idFilter('');
            }
            codeFilter('');
            nameFilter('');
            descriptionFilter('');
            dataTypeFilter('');
            helpFilter('');
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let dataTypeSelectOptions = [];
                let allDataType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('DATA_TYPE') !== -1);
                allDataType.forEach((dataType, index) => {
                    dataTypeSelectOptions[index] = {
                        value: dataType.code,
                        label: getInternationalization(this.props.language, dataType.code, this.props.allCodes, this.props.activeLanguages)
                    }
                });

                let valuesColumns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true
                }, {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true
                }, {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={row.code}
                            listInternationalization={this.props.allCodes}
                        />
                    )
                }];

                const expandRow = {
                    renderer: row => (
                        <div className="card card-body" key={row.id + "container"}>
                            <h6 className="card-title text-left" key={row.id + "title"}>
                                <FormattedMessage id="project.elements.attributes.values" />
                            </h6>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                columns={valuesColumns}
                                data={row.valueGroupCode.listCode}
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                rowClasses="text-left"
                                striped
                                condensed
                                key={row.id + 'table'}
                            />
                        </div>
                    ),
                    expandByColumnOnly: true,
                    expanded: this.state.expandedRows
                }

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.codeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'name',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.name" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.name" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.nameFilter = filter;
                                return { filters }
                            })
                        }
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code.code, this.props.allCodes, this.props.activeLanguages)
                }, {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.descriptionFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.dataType} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }).toLocaleLowerCase() }),
                        options: dataTypeSelectOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.dataTypeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'helpCode',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.help" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatExtraData: this.props.language,
                    // It's necessary to pass the language as formatExtraData 
                    // to rerender column when user changes language.
                    formatter: (cellContent, row, rowIndex, formatExtraData) => {
                        if (hasHelp(formatExtraData, row.helpCode)) {
                            return (
                                <InternationalizationRender
                                    locale={this.props.language}
                                    value={row.helpCode.code}
                                    listInternationalization={this.props.allCodes}
                                />
                            )
                        } else {
                            return <span>-</span>
                        }
                    },
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.help" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.helpFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: [this.state.expandedRows, this.props.language],
                    formatter: (cellContent, row, rowIndex, formatExtraData) => {
                        return (
                            <div className="text-left">
                                <span
                                    className="btn-link actionButton"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            addModifyAttributeTypeModalShow: true,
                                            attributeTypeToModify: row
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"modify" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span className="btn-link actionButton"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            deleteAttributeTypeDialogShow: true,
                                            deleteAttributeTypeCode: row.code.code,
                                            deleteAttributeTypeId: row.id
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"delete" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                                {hasHelp(this.props.language, row.helpCode) ?
                                    <span className="btn-link helpTooltip"
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5em"
                                        }}
                                        id={"help" + row.id}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-html="true"
                                        title={
                                            getInternationalization(this.props.language, row.helpCode.code, this.props.allCodes, this.props.activeLanguages)
                                        }
                                        data-original-title={
                                            getInternationalization(this.props.language, row.helpCode.code, this.props.allCodes, this.props.activeLanguages)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </span> : ""}
                                {row.dataType === "SELECT" || row.dataType === "MULTISELECT" ?
                                    <span className="btn-link actionButton"
                                        id={"showHideValues" + row.id}
                                        onClick={() => handleBtnClick(row)}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5em"
                                        }}
                                        title={this.props.intl.formatMessage({ id: "project.elements.attributes.showHideValues" })}
                                    >
                                        {<FontAwesomeIcon icon={!formatExtraData[0].includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />}
                                    </span>
                                    :
                                    ""
                                }
                            </div>
                        )
                    }
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.attributeType" /></h3>
                            <div className="text-right">
                                <button type="button" className="btn btn-primary"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            addModifyAttributeTypeModalShow: true,
                                            attributeTypeToModify: null
                                        })
                                    }
                                    id="addAttributeType"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.attributes.add" />
                                </button>
                            </div>

                            {/* Add/Modify attribute type dialog */}
                            <AddModifyAttributeType
                                modalShow={this.state.addModifyAttributeTypeModalShow}
                                attributeTypeToModify={this.state.attributeTypeToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        addModifyAttributeTypeModalShow: false,
                                        totalAttributeType: this.props.totalAttributeType
                                    });
                                    if (this.state.attributeTypeToModify) {
                                        clearAllFilters(this.state.attributeTypeToModify.id);
                                    } else {
                                        let newAttributeType =
                                            this.props.totalAttributeType[this.props.totalAttributeType.length - 1];
                                        if (newAttributeType && newAttributeType.id) {
                                            clearAllFilters(newAttributeType.id);
                                        } else {
                                            clearAllFilters();
                                        }
                                    }
                                }}
                                hideModalWindow={() => this.setState({ addModifyAttributeTypeModalShow: false })}
                            />

                            {/* Delete attribute type dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteAttributeTypeDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteAttributeTypeCode}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteAttributeTypeDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteAttributeType(this.state.deleteAttributeTypeId,
                                        () => {
                                            let newTotalPages = Math.ceil(this.props.totalAttributeType.length / this.state.currentSize);
                                            if (newTotalPages > 0 && newTotalPages < this.state.currentPage) {
                                                // First the page is changed, then it sets the new data. If done at the same time, 
                                                // react-bootstrap-table-next will throw an exception
                                                this.setState(previousState => {
                                                    return {
                                                        currentPage: previousState.currentPage - 1,
                                                        deleteAttributeTypeDialogShow: false
                                                    }
                                                }, () => this.setState({ totalAttributeType: this.props.totalAttributeType }));
                                            } else {
                                                this.setState({
                                                    deleteAttributeTypeDialogShow: false,
                                                    totalAttributeType: this.props.totalAttributeType
                                                });
                                            }
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />

                            <br />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={this.state.totalAttributeType ?
                                    this.state.totalAttributeType : []
                                }
                                noDataIndication={this.state.totalAttributeType ?
                                    this.props.intl.formatMessage({ id: "project.elements.attributes.noElements" }) :
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: this.state.currentSortField,
                                    order: this.state.currentSortOrder
                                }]}
                                sort={{
                                    dataField: this.state.currentSortField,
                                    order: this.state.currentSortOrder
                                }}
                                striped
                                condensed
                                rowClasses="text-left"
                                expandRow={expandRow}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                pagination={paginationFactory({
                                    sizePerPage: this.state.currentSize,
                                    showTotal: true,
                                    alwaysShowAllBtns: true,
                                    hidePageListOnlyOnePage: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    onPageChange: (page, sizePerPage) => {
                                        this.setState({ expandedRows: [], sizePerPage });
                                    },
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({ sizePerPage: sizePerPage })
                                    },
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    ),
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                })}
                            />
                        </div>
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;
        }

        if (!this.props.user && this.props.allCodes) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AllAttributeType)));