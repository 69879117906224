export default {
    "project.global.exceptions.NetworkError": 'Non se puido conectar co servidor',
    'project.app.Header.riskzones': 'Zonas de Risco',
    'project.app.Header.viewer': 'Visor xeográfico',
    'project.app.Header.geometricElements': 'Táboas de {plural}',
    'project.app.Header.admin': 'Administración',
    'project.app.Header.admin.users': 'Usuarios',
    'project.app.Header.admin.elementType': 'Tipos de {plural}',
    'project.app.Header.admin.attributeType': 'Tipos de atributos',
    'project.app.Header.admin.configurationParameters': 'Parámetros de configuración',
    'project.app.Header.admin.layer': 'Capas',
    'project.app.Header.admin.layerGroup': 'Grupos de capas',
    'project.app.Header.admin.attachedFile': 'Ficheiros adxuntos',
    'project.app.Header.admin.geometricElementStyles': 'Estilos para {plural}',
    'project.app.Header.myContributions': '{myElements}',
    'project.app.Header.addGeometricElement': 'Crear {singular}',
    'project.app.Header.login': 'Iniciar sesión',
    'project.app.Header.signUp': 'Crear conta',
    'project.app.Header.logout': 'Pechar sesión',
    'project.app.Header.password': 'Contrasinal',
    'project.app.Header.downloadAPK': 'Descargar aplicación Android',
    'project.app.Body.search': 'Buscar',
    'project.app.Body.accept': 'Aceptar',
    'project.app.Body.cancel': 'Cancelar',
    'project.app.Body.back': 'Anterior',
    'project.app.Body.next': 'Seguinte',
    'project.app.Body.save': 'Gardar',
    'project.app.Body.return': '← Volver á páxina principal',
    'project.app.Body.field.required': 'O campo "{field}" é obrigatorio',
    'project.app.Body.field.positive': 'O campo "{field}" debe ser positivo',

    'project.common.close': 'Pechar',
    'project.common.ErrorDialog.title': 'Erro',
    'project.common.permissionDenied': 'Non ten permiso para acceder a esta páxina',
    'project.common.mustLogin': 'Debe iniciar sesión para poder acceder a esta páxina',
    'project.common.action': 'Accións',
    'project.common.modify': 'Modificar',
    'project.common.center': 'Centrar',
    'project.common.modifyOnViewer': 'Modificar no visor xeográfico',
    'project.common.centerOnViewer': 'Ver no mapa',
    'project.common.selectOneOption': 'Selecciona unha opción',
    'project.common.viewElements': 'Ver {plural}',
    'project.common.attributes': 'Atributos',
    'project.common.delete': 'Eliminar',
    'project.common.deleteSomething': 'Eliminar {something}',
    'project.common.download': 'Descargar',
    'project.common.viewFiles': 'Ver fotos',
    'project.common.deleteFile': 'Eliminar ficheiro',
    'project.common.addFilesButton': 'Engadir ficheiros',
    'project.common.addFiles': 'Engadir ficheiros a {singular} {id}',
    'project.common.yes': 'Si',
    'project.common.no': 'Non',
    'project.common.filters': 'Filtros',
    'project.common.filters.clear': 'Quitar todos os filtros',
    'project.common.filepond.labelIdle': 'Arrastra e solta os teus ficheiros ou <span class="filepond--label-action">Examina</span>',
    'project.common.filepond.labelTapCancel': 'Pulsa para cancelar',
    'project.common.filepond.labelLoading': 'Cargando',
    'project.common.filepond.labelWaitForSize': 'Esperando polo tamaño',
    'project.common.tables.totalElements': 'Amosando do {from} ao {to} de {total} ',
    'project.common.tables.filter.text.placeholder': 'Buscar {column}',
    'project.common.tables.filter.select.placeholder': 'Seleccionar {column}',
    'project.common.location': 'Localización',
    'project.common.location.province': 'Provincia',
    'project.common.location.municipality': 'Concello',
    'project.common.location.parish': 'Parroquia',
    'project.common.dates': 'Datas',
    'project.common.showDetails': 'Ver en detalle',
    'project.common.pagination.firstPageTitle': 'Primeira páxina',
    'project.common.pagination.lastPageTitle': 'Última páxina',
    'project.common.pagination.nextPageTitle': 'Páxina seguinte',
    'project.common.pagination.prePageTitle': 'Páxina anterior',
    'project.common.legendType': 'Tipo de lenda',
    'project.common.legendType.url': 'URL da lenda',
    'project.common.legendType.file': 'Ficheiro da imaxe',
    'project.common.legendType.file.error': 'Debe seleccionar un ficheiro',
    'project.common.legendType.file.existent': 'Imaxe existente',
    'project.common.legendType.file.unexistent': 'Nova imaxe',
    'project.common.images.error': 'Non foi posible cargar a imaxe',

    'project.elements.data': 'Datos',
    'project.elements.new': 'Crear {element}',
    'project.elements.myElements.message': "Nesta táboa amósanse {plural} que se publicaron",
    'project.elements.anonymous.message': "Para introducir {plural} debe {link}",
    'project.elements.modify': 'Modificar {element}',
    'project.elements.modify.error': 'Non se puido iniciar a modificación',
    'project.elements.noElements': 'Non existen {plural} para o tipo seleccionado',
    'project.elements.types.noElements': 'Non existen tipos de {plural}',
    'project.elements.attributes.noElements': 'Non existen tipos de atributos',
    'project.elements.map.zoomInTipLabel': 'Achegarse',
    'project.elements.map.zoomOutTipLabel': 'Afastarse',
    'project.elements.map.fitToExtentTip': 'Ver a máxima extensión',
    'project.elements.map.zoomToExtentTip': 'Zoom por encadre',
    'project.elements.map.popupTip': 'Consultar información sobre {plural} mediante clic',
    'project.elements.map.measureLengthTip': 'Medir distancias',
    'project.elements.map.measureAreaTip': 'Medir áreas',
    'project.elements.map.addElementTip': 'Crear {singular}',
    'project.elements.map.fullScreenTip': 'Pantalla completa',
    'project.elements.map.layersTip.show': 'Amosar capas',
    'project.elements.map.layersTip.hide': 'Ocultar capas',
    'project.elements.map.legendTip': 'Lenda',
    'project.elements.map.otherLayers': 'Outras capas',
    'project.elements.map.elements': '{plural}',
    'project.elements.map.provinces': 'Provincias/Distritos',
    'project.elements.map.councils': 'Concellos/Concelhos',
    'project.elements.map.parishes': 'Parroquias/Freguesias',
    'project.elements.map.entityPopulations': 'Entidades de poboación',
    'project.elements.map.counties': 'Comarcas',
    'project.elements.map.entityPopulation': 'Entidade de poboación',
    'project.elements.map.selectProvince': '-- Seleccione provincia --',
    'project.elements.map.selectCouncil': '-- Seleccione concello --',
    'project.elements.map.selectParish': '-- Seleccione parroquia --',
    'project.elements.map.selectEntityPopulation': '-- Seleccione entidade de poboación --',
    'project.elements.map.searchEntityPopulation': 'Buscar',
    'project.elements.map.progressiveCenter': 'Busca progresiva',
    'project.elements.map.searchCenter': 'Busca directa',
    'project.elements.map.searchType': 'Tipo de busca',
    'project.elements.map.opacityLabelTip': 'Opacidade',
    'project.elements.map.exportPDFTip': 'Exportar a PDF',
    'project.elements.map.exportPDF': 'Exportar a PDF',
    'project.elements.map.exportPDF.size': 'Tamaño de páxina',
    'project.elements.map.exportPDF.size.a4': 'A4',
    'project.elements.map.exportPDF.size.a3': 'A3',
    'project.elements.map.exportPDF.orientation': 'Orientación',
    'project.elements.map.exportPDF.orientation.landscape': 'Horizontal',
    'project.elements.map.exportPDF.orientation.portrait': 'Vertical',
    'project.elements.map.exportPDF.includeLegend': 'Incluír lenda',
    'project.elements.map.exportPDF.includeScale': 'Incluír escala',
    'project.elements.map.exportPDF.save': 'Exportar',
    'project.elements.map.exportPDFError': 'Produciuse un erro ao xerar o PDF',
    'project.elements.map.popupControl.failedLayers': 'Non se puideron consultar as capas: {failedLayers}',
    'project.elements.map.popupControl.select.placeholder': 'Seleccionar',
    'project.elements.map.popupControl.select.noOptionsText': 'Non se atoparon opcións',
    'project.elements.map.popupControl.select.noSearchResultsText': 'Non se atopou ningún resultado',
    'project.elements.map.popupControl.select.selectAllText': 'Seleccinar todo',
    'project.elements.map.popupControl.select.searchPlaceholderText': 'Buscar...',
    'project.elements.map.popupControl.select.optionsSelectedText': 'opcións seleccionadas',
    'project.elements.map.popupControl.select.optionSelectedText': 'opción seleccionada',
    'project.elements.map.popupControl.select.allOptionsSelectedText': 'Todas',
    'project.elements.map.popupControl.select.clearButtonText': 'Limpar selección',
    'project.elements.map.popupControl.select.moreText': 'máis...',
    'project.elements.map.popupControl.noResult': 'Non se atopou ningún obxecto nese punto',
    'project.elements.map.bookmarkControl.title': 'Marcadores',
    'project.elements.map.bookmarkControl.placeholder': 'Engadir novo marcador...',
    'project.elements.selectOneOption': 'Selecciona unha opción',
    'project.elements.files': 'Ficheiros asociados',
    'project.elements.files.hideShow': 'Amosar/Ocultar ficheiros asociados',
    'project.elements.files.noFiles': 'Non se atoparon ficheiros asociados',
    'project.elements.values': 'Valores',
    'project.elements.name': 'Nome',
    'project.elements.geometryType': 'Tipo de xeometría',
    'project.elements.styles': 'Estilos',
    'project.elements.styles.show': 'Previsualizar',
    'project.elements.styles.point': 'Estilos para punto',
    'project.elements.styles.point.add': 'Engadir estilo para punto',
    'project.elements.styles.polygon': 'Estilos para polígono',
    'project.elements.styles.polygon.add': 'Engadir estilo para polígono',
    'project.elements.styles.line': 'Estilos para liña',
    'project.elements.styles.line.add': 'Engadir estilo para liña',
    'project.elements.styles.delete.message': 'Está seguro de que desexa eliminar o estilo "{style}"?',
    'project.elements.styles.name': 'Nome de estilo',
    'project.elements.styles.file': 'Arquivo de estilo',
    'project.elements.styles.export': 'Exportar estilo',
    'project.elements.styles.styleType': 'Formato',
    'project.elements.pointStyleName': 'Nome do estilo para punto',
    'project.elements.lineStyleName': 'Nome do estilo para liña',
    'project.elements.polygonStyleName': 'Nome do estilo para polígono',
    'project.elements.description': 'Descrición',
    'project.elements.geom': 'Xeometría',
    'project.elements.geom.LineString': 'Liña',
    'project.elements.geom.Point': 'Punto',
    'project.elements.geom.Polygon': 'Polígono',
    'project.elements.geom.LinearRing': 'Anel lineal',
    'project.elements.geom.MultiPoint': 'Multipunto',
    'project.elements.geom.MultiLineString': 'Multilínea',
    'project.elements.geom.MultiPolygon': 'Multipolígono',
    'project.elements.geom.Circle': 'Círculo',
    'project.elements.files.filename': 'Nome',
    'project.elements.files.format': 'Formato',
    'project.elements.files.type': 'Tipo mime',
    'project.elements.files.size': 'Tamaño',
    'project.elements.values.attribute': 'Atributo',
    'project.elements.values.value': 'Valor',
    'project.elements.values.order': 'Orde',
    'project.elements.creationDate': 'Introdución',
    'project.elements.modificationDate': 'Última modificación',
    'project.elements.lessCreationDate': 'Data de introdución igual ou anterior a',
    'project.elements.lessModificationDate': 'Data de modificación igual ou anterior a',
    'project.elements.moreCreationDate': 'Data de introdución igual ou posterior a',
    'project.elements.moreModificationDate': 'Data de modificación igual ou posterior a',
    'project.elements.isPublic': 'Amosar para todos',
    'project.elements.isPublic.help': 'Marque esta opción se quere que a xeometría, e a súa información asociada, sexan visibles para calquera usuario/a',
    'project.elements.isReviewed': 'Está revisado?',
    'project.elements.internalComments': 'Obs. internas',
    'project.elements.owner': 'Autor',
    'project.elements.type.add': 'Engadir tipo de {singular}',
    'project.elements.type.add.name': 'Nome',
    'project.elements.type.modify': 'Modificar tipo de {singular}',
    'project.elements.type.modify.name': 'Nome',
    'project.elements.type.add.attribute': 'Atributo',
    'project.elements.type.showAttributes': 'Amosar/Ocultar atributos asociados',
    'project.elements.delete.message': 'Vai eliminar "{element}", desexa continuar?',
    'project.elements.geometricElement.delete.message': `Vai eliminar "{element}", desexa continuar? 
    {alert}`,
    'project.elements.geometricElement.delete.message.alert': 'Ao facer a eliminación, eliminaranse tamén as distintas relacións que teña e os seus ficheiros asociados',
    "project.elements.files.fileNoExistsHeader": 'O ficheiro non existe',
    "project.elements.files.fileNoExistsBody": 'Non se atopou o ficheiro',
    'project.elements.legend': 'Lenda',
    'project.elements.legend.title': 'Lenda do tipo de {singular} {element}',
    'project.elements.legend.show': 'Amosar lenda',
    'project.elements.opacity': 'Opacidade',
    'project.elements.opacity.defaultOpacity': 'Opacidade inicial da capa',
    'project.elements.opacity.defaultOpacity.help': 'O valor debe estar entre 0 e 100. Se non se indica ningún valor a capa amosarase cunha opacidade do 100 %',
    'project.elements.opacity.defaultOpacity.error': 'O valor do campo "Opacidade inicial da capa" debe estar entre 0 e 100',
    'project.elements.opacity.allowChangeOpacity.table': 'Amosar o control no visor',
    'project.elements.opacity.allowChangeOpacity.form': 'Amosar o control de cambio de opacidade no visor',
    'project.elements.type.join.canJoin': 'Permitir unirse/apuntarse a {plural} deste tipo',
    'project.elements.type.join.tooltipText': 'Texto para o título do formulario de inserción',
    'project.elements.type.join.tableText': 'Texto para o título da táboa',
    'project.elements.join.isPublic': 'Publicar?',
    'project.elements.join.isPublic.help.user': 'Se non se publica, só vostede e os administradores poderán velo',
    'project.elements.join.isPublic.help.admin': 'Se non se publica, só os administradores e o creador poderán velo',
    'project.elements.join.isReviewed': 'Está revisado?',
    'project.elements.join.comments': 'Observacións',
    'project.elements.join.user': 'Usuario',
    'project.elements.join.noJoin': 'Ningún usuario',
    'project.elements.join.anonymous.message': 'Debe {link} para realizar esta operación',
    'project.elements.join.delete.message': 'Está seguro?',

    'project.elements.relations': 'Relacións',
    'project.elements.relations.noRelations': 'Non se atoparon relacións',
    "project.elements.relations.relate": 'Crear relación',
    'project.elements.relations.geometricElementType': 'Tipo de {singular}',
    'project.elements.relations.geometricElementId': 'Id {singular}',
    'project.elements.relations.geometricElementDescription': 'Descrición',
    'project.elements.relations.geometricElementAttributes': 'Atributos',
    'project.elements.relations.add': 'Crear relación',
    'project.elements.relations.modify': 'Modificar relación',
    'project.elements.relations.delete.message': 'Vai eliminar a relación entre "{geometricElement1}" e "{geometricElement2}", ¿Desexa continuar?',
    'project.elements.relations.author': 'Autor relación',
    'project.elements.relations.id': 'Id relación',
    'project.elements.relations.geometricElement1': 'Id de {singular} 1',
    'project.elements.relations.geometricElement2': 'Id de {singular} 2',
    'project.elemens.relations.geometricElement.showDetails': 'Ver {singular} en detalle',
    'project.elements.relations.isPublic': 'A relación é pública?',
    'project.elements.relations.isReviewed': 'A relación está revisada?',
    'project.elements.relations.comments': 'Observacións da relación',
    'project.elements.relations.anonymous.message': 'Debe {link} para crear unha nova relación',

    'project.elements.attributes.dataType': 'Tipo de dato',
    'project.elements.attributes.dataType.pick': 'Selecciona o tipo de dato da lista',
    'project.elements.attributes.attributeOrder': 'Orde',
    'project.elements.attributes.code': 'Código',
    'project.elements.attributes.values': 'Posibles valores',
    'project.elements.attributes.showHideValues': 'Amosar/Ocultar posibles valores',
    'project.elements.attributes.add': 'Engadir tipo de atributo',
    'project.elements.attributes.modify': 'Modificar tipo de atributo',
    'project.elements.attributes.attributeName': 'Nome do atributo',
    'project.elements.attributes.help': 'Axuda',
    'project.elements.attributes.translations': 'Internacionalización',
    'project.elements.attributes.maxSize': 'Tamaño máximo',
    'project.elements.attributes.mandatory': 'Obrigatorio',
    'project.elements.attributes.type': 'Tipo de atributo',
    'project.elements.attributes.description': 'Descrición',
    'project.elements.attributes.name': 'Nome',

    'project.elements.geometricLayer.layerGroup.name': 'Nome público',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': 'Subgrupo de "Outras capas"?',
    'project.elements.geometricLayer.layerGroup.order': 'Orde',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': 'Inicialmente aberto?',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Amosar/Ocultar capas asociadas',
    'project.elements.geometricLayer.layerGroup.placeBehindOtherLayers': 'Colocar despois de "Outras capas"',
    'project.elements.geometricLayer.layerGroup.add': 'Engadir grupo de capas',
    'project.elements.geometricLayer.layerGroup.modify': 'Modificar grupo de capas',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'Vai eliminar o grupo de capas "{element}", desexa continuar?',
    'project.elements.geometricLayer.layerGroup.noElements': 'Non existe ningún grupo de capas',
    'project.elements.geometricLayer.layer.tableName': 'Nome da táboa da base de datos interna',
    'project.elements.geometricLayer.layer.internalName': 'Nome interno',
    'project.elements.geometricLayer.layer.name': 'Nome público',
    'project.elements.geometricLayer.layer.order': 'Orde',
    'project.elements.geometricLayer.layer.initiallyVisible': 'Inicialmente visible?',
    'project.elements.geometricLayer.layer.serverUrl': 'URL do servidor',
    'project.elements.geometricLayer.layer.serverUrl.internalLayer': 'Capa interna',
    'project.elements.geometricLayer.layer.type': 'Tipo',
    'project.elements.geometricLayer.layer.type.help': `<ul>
    <li><b>WMS:</b> crearase unha capa de tipo WMS. Débense cubrir os campos <i>URL do servidor</i> e <i>Nome interno</i>, pódendose poñer varios nomes de capas separados por comas.</li>
    <li><b>WMS da base de datos interna:</b> crearase unha capa no servidor e unha capa de tipo WMS. Débense cubrir os campos <i>Nome da tába da base de datos interna</i> e <i>Nome interno</i>.</li>
    <li><b>OpenStreetMap:</b> crearase a capa <i>estándar</i> de OpenStreetMap.</li>
    <li><b>XYZ:</b> crearase unha capa de tipo XYZ. Débese cubrir o campo <i>URL do servidor</i> cunha URL do tipo <i>http://xxxxx?x={x}&y={y}&z={z}</i>. Por exemplo: 
    <ul>
    <li>Capa <i>estándar</i> de Google <i>http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}</i></li>
    <li>Capa de ortofoto de Google <i>http://mt0.google.com/vt/lyrs=m&hl=gl&x={x}&y={y}&z={z}</i></li>
    </ul>
    </li>
    </ul>`,
    'project.elements.geometricLayer.layer.showAllLayersOnLegend': 'Amosar todas as capas na lenda',
    'project.elements.geometricLayer.layer.showAllLayersOnLegend.help': 'Se se marca esta opción, na lenda que se amosa no visor xeográfico para esta capa, só se amosará a lenda da primera das capas configuradas en "Nome interno"',
    'project.elements.geometricLayer.layer.internalComments': 'Observacións internas',
    'project.elements.geometricLayer.layer.legend': 'Lenda',
    'project.elements.geometricLayer.layer.legend.title': 'Lenda da capa {layer}',
    'project.elements.geometricLayer.layer.legend.show': 'Amosar lenda',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Grupo de capas',
    'project.elements.geometricLayer.layer.version': 'Versión',
    'project.elements.geometricLayer.layer.version.help': 'Se non se selecciona ningunha versión, empregarase por defecto a versión 1.3.0',
    'project.elements.geometricLayer.layer.add': 'Engadir capa',
    'project.elements.geometricLayer.layer.modify': 'Modificar capa',
    'project.elements.geometricLayer.layer.noElements': 'Non existe ningunha capa',
    'project.elements.geometricLayer.layer.deleteMessage': 'Vai eliminar a capa "{element}", ¿Desexa continuar?',
    'project.elements.geometricLayer.layer.opacity': 'Opacidade',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity': 'Opacidade inicial da capa',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.help': 'O valor debe estar entre 0 e 100. Se non se indica ningún valor a capa amosarase cunha opacidade do 100 %',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.error': 'O valor do campo "Opacidade inicial da capa" debe estar entre 0 e 100',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.table': 'Amosar o control no visor',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.form': 'Amosar o control de cambio de opacidade no visor',
    'project.elements.geometricLayer.layerGroup.order.help': 'A orde ten que ser única dentro de cada un dos tres bloques de grupos de capas: antes do grupo "Outras capas", dentro do grupo de "Outras capas" e despois do grupo "Outras capas"',
    'project.elements.geometricLayer.layer.supportsGetFeatureInfo': 'Soporta a operación GetFeatureInfo',
    'project.elements.geometricLayer.layer.informationFormatToUse': 'Formato para GetFeatureInfo',
    'project.elements.geometricLayer.layer.useProxy': 'Usar proxy no visor web',
    'project.elements.geometricLayer.layer.useProxyAndroid': 'Usar proxy no visor Android',
    'project.elements.geometricLayer.layer.useProxy.help': 'Esta opción debe empregarse só cando o acceso á capa falla sen o uso do proxy. <b>O uso do proxy implica unha maior carga no servidor da aplicación e un aumento no tráfico de datos de entrada e saída.</b>',
    'project.geometricLayer.layer.styleFile': 'Introducir ficheiro de estilo',
    'project.geometricLayer.layer.styleFile.help': 'Se non se carga un ficheiro de estilo (.qml ou .sld), o servidor asignará un automaticamente',
    'project.geometricLayer.layer.styleFile.existent': 'Conservar o estilo actual',
    'project.geometricLayer.layer.styleFile.new': 'Introducir novo estilo',
    'project.geometricLayer.layer.styleFile.copyFromLayer': 'Tomar o estilo de capa shape/base de datos',
    'project.geometricLayer.layer.styleFile.copyFromElement.alert': 'Seleccione un estilo que coincida coa xeometría desta capa',
    'project.geometricLayer.layer.styleFile.copyFromElement': 'Tomar o estilo de capa de {plural}',
    'project.geometricLayer.layer.styleFile.new.help': 'Ao modificar o estilo sobrescríbese o actual',
    'project.geometricLayer.layer.styleFile.title': 'Estilo de visualización',
    'project.geometricLayer.layer.shapeFile.title': 'Shape',
    'project.geometricLayer.layer.shapeFile.add': 'Introducir ficheiro',
    'project.geometricLayer.layer.shapeFile.modify': 'Nome do ficheiro shape',
    'project.geometricLayer.layer.shapeFile.help': 'Débese cargar un ficheiro zip que conteña o seguinte: <ul><li>Ficheiro .shp</li><li>Ficheiro .shx</li><li>Ficheiro .dbf</li><li>Ficheiro .prj (opcional): <b>se non o contén, débese cubrir o campo <i>Sistema de coordenadas</i></b></li><ul>',
    'project.geometricLayer.layer.shapeFile.existent': 'Conservar o shape actual',
    'project.geometricLayer.layer.shapeFile.new': 'Introducir novo shape',
    'project.elements.geometricLayer.layer.shapeFile.coordinateSystem': 'Sistema de coordenadas',
    'project.elements.geometricLayer.layer.shapeFile.coordinateSystem.help': 'Se se deixa en branco, empregarase o valor especificado no ficheiro .prj contido no zip. <b>Se o zip non contén ningún ficheiro .prj débese cubrir este campo.</b> O formato debe ser <b>EPSG:<i>Código do sistema de coordenadas</i></b> por exemplo <b>EPSG:25829</b>',

    'project.configurationParameter.name': 'Nome',
    'project.configurationParameter.code': 'Código',
    'project.configurationParameter.value': 'Valor',
    'project.configurationParameter.userInterface': 'Interface de usuario?',
    'project.configurationParameter.noDataIndication': 'Non existen parámetros de configuración',
    'project.configurationParameter.modify': 'Modificar parámetro "{parameter}"',
    'project.configurationParameter.modify.defaultLanguage': 'Idioma predeterminado',
    'project.configurationParameter.required': 'O valor non pode ser nulo',
    'project.configurationParameter.delete': 'Eliminar valor',
    'project.configurationParameter.delete.message': 'Desexa eliminar o valor do parámetro de configuración {configurationParameter}?',

    'project.users.password': 'Contrasinal',
    'project.users.confirmPassword': 'Confirmar contrasinal',
    'project.users.confirmPassword.error': 'Os contrasinais non coinciden',
    'project.users.locale': 'Idioma',
    'project.users.locale.pick': 'Selecciona un idioma da lista',
    'project.users.locale.galician': 'Galego',
    'project.users.locale.spanish': 'Español',
    'project.users.locale.english': 'Inglés',
    'project.users.login': 'Nome de usuario',
    'project.users.currentPassword': 'Contrasinal actual',
    'project.users.newPassword': 'Novo contrasinal',
    'project.users.confirmNewPassword': 'Confirme o novo contrasinal',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.lock': 'Bloquear conta',
    'project.users.user': 'Usuario',
    'project.users.username': "Nome de usuario",
    'project.users.locked': "Está bloqueado?",
    'project.users.role': "Rol",
    'project.users.changePassword': 'Cambiar contrasinal',
    'project.users.createUser': 'Crear usuario',
    'project.users.noDataIndication': 'Non existen contas de usuario',

    'project.attachedFiles.add': 'Engadir ficheiro adxunto',
    'project.attachedFiles.add.file': 'Ficheiro',
    'project.attachedFiles.modify': 'Modificar ficheiro adxunto',
    'project.attachedFiles.description': 'Descrición',
    'project.attachedFiles.type': 'Tipo',
    'project.attachedFiles.internalFilename': 'Nome interno',
    'project.attachedFiles.originalFilename': 'Nome orixinal',
    'project.attachedFiles.size': 'Tamaño',
    'project.attachedFiles.fileFormat': 'Formato de ficheiro',
    'project.attachedFiles.noElements': 'Non existen ficheiros adxuntos',
    'project.attachedFiles.errors.maxSize': 'O ficheiro {filename} que intenta engadir supera o tamaño máximo permitido ({maxSize})',
    'project.attachedFiles.errors.formatNotPermitted': 'O ficheiro {filename} que intenta engadir ten un formato non admitido',
    'project.attachedFiles.errors.mustBeImage': 'O ficheiro debe ser unha imaxe',
    'project.attachedFiles.error.cannotDownloadFileHeader': 'Erro',
    'project.attachedFiles.error.cannotDownloadFileBody': 'O ficheiro non puido descargarse'
}