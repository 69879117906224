import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as selectors from '../selectors';
import * as geometricElementSelectors from '../../geometricElements/selectors';

const mapStateToProps = function (state) {

    return {
        locale: selectors.getLanguage(state),
        activeLanguages: selectors.getActiveLanguages(state),
        listInternationalization: geometricElementSelectors.getAllCodes(state)
    }
}

export const PARAMETER_ELEMENT_TEXT_SINGULAR = "ELEMENT_TEXT_SINGULAR_VALUE";

export const PARAMETER_ELEMENT_TEXT_PLURAL = "ELEMENT_TEXT_PLURAL_VALUE";

export const PARAMETER_MY_ELEMENTS_TEXT = "MY_ELEMENTS_TEXT_VALUE";

export function getInternationalization(locale, value, listInternationalization, activeLanguages) {
    if (listInternationalization) {
        let listTranslations = Object.values(listInternationalization).filter(internationalization =>
            internationalization.code === value)
        if (listTranslations.length !== 0) {
            listTranslations = listTranslations[0].listInternationalizationDto;
        } else {
            return value
        }
        let description = Object.values(listTranslations)
            .filter(translation => translation.language.indexOf(locale) !== -1);

        if (description.length) {
            description = description[0].description;
        } else {
            // Return default language internationalization if activeLanguages has value
            if (activeLanguages) {
                let defaultLanguage = activeLanguages[0].split("-")[0].toLowerCase();
                description = Object.values(listTranslations)
                    .filter(translation => translation.language.indexOf(defaultLanguage) !== -1);
                if (description.length) {
                    description = description[0].description;
                    // If default language has no internationalization, return the code
                } else {
                    description = value;
                }
            } else {
                description = null;
            }
        }

        return description;
    }
    return value;
}

export function hasHelp(locale, helpCode) {
    let hasHelp = false;
    if (locale && helpCode) {
        let helpInternationalization = helpCode.listInternationalizationDto
            .filter(help => help.language === locale);
        if (helpInternationalization.length > 0) {
            if (helpInternationalization[0].description && helpInternationalization[0].description !== "") {
                hasHelp = true;
            }
        }
    }

    return hasHelp;
}

class InternationalizationRender extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            locale: props.locale,
            listInternationalization: props.listInternationalization
        }
    }

    render() {

        let { locale, value, listInternationalization, activeLanguages } = this.props;

        return getInternationalization(locale, value, listInternationalization, activeLanguages)
    }
}

export default withRouter(connect(mapStateToProps)(InternationalizationRender));