import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedTime, injectIntl } from 'react-intl';
import * as userSelectors from '../../users/selectors';
import * as selectors from '../selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import * as appSelectors from '../../app/selectors';
import InternationalizationRender from '../../app/components/InternationalizationRender';
import { faEdit, faMapMarkerAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { sortValuesAndGroupByAttributeType } from '../../common/util';

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
        geometricElement: selectors.getGeometricElement(state),
        configurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    });
}

const GeometricElementData = (props) => {

    let RELATE_ELEMENTS_TO_PARISHES;

    if (props.configurationParameters) {
        RELATE_ELEMENTS_TO_PARISHES = props.configurationParameters.filter(
            configurationParameter => configurationParameter.code === "RELATE_ELEMENTS_TO_PARISHES")[0].value;
    }

    let { attributes, values } = sortValuesAndGroupByAttributeType(props.geometricElement.listAttributeValueDto);

    return (
        <div className="card mb-3">
            <h5 className="card-header text-left">
                <FormattedMessage id="project.elements.data" />
            </h5>
            <div className="card-body container-fluid">
                {props.authenticatedUser ?
                    props.authenticatedUser.userRoleDto.code === "ADMIN" ||
                        (props.geometricElement.isOwnerUser) ?
                        <div className="text-right mb-3">
                            <span id={"centerGeometricElement"}
                                onClick={() => {
                                    props.handleCenterFeature(props.geometricElement);
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                className="actionButton btn-link mx-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title={props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                            >
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                            <span id={"modifyGeometricElement"}
                                onClick={() => {
                                    props.modifyGeometricElement();
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                className="actionButton btn-link mx-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title={props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span id={"deleteGeometricElement"}
                                onClick={() => {
                                    props.deleteGeometricElement();
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                className="actionButton btn-link mx-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title={props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                        </div>
                        :
                        <div className="text-right mb-3">
                            <span id={"centerGeometricElement"}
                                onClick={() => {
                                    props.handleCenterFeature(props.geometricElement);
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                className="actionButton btn-link mx-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title={props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                            >
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                        </div>
                    :
                    <div className="text-right mb-3">
                        <span id={"centerGeometricElement"}
                            onClick={() => {
                                props.handleCenterFeature(props.geometricElement);
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                            className="actionButton btn-link mx-1"
                            data-toggle="tooltip"
                            data-placement="left"
                            title={props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </span>
                    </div>
                }
                <div className="row row-cols-md-1 row-cols-md-2 row-cols-md-3 row-cols-md-4 row-cols-md-5 row-cols-md-6 card-block">
                    <div className="col-md mb-3 mx-3">
                        <div className="row font-weight-bold">Id</div>
                        <div className="row">{props.geometricElement.id}</div>
                    </div>
                    <div className="col-md mb-3 mx-3">
                        <div className="row font-weight-bold"><FormattedMessage id="project.elements.description" /></div>
                        <div className="row">{props.geometricElement.description}</div>
                    </div>
                    {attributes.length !== 0 ?
                        attributes.map((attribute, index) =>
                            <div key={attribute.code} className="col-md mb-3 mx-3">
                                <div className="row font-weight-bold">
                                    <InternationalizationRender
                                        locale={props.language}
                                        value={attribute.code}
                                        listInternationalization={props.allCodes}
                                    />
                                </div>
                                <div className="row">
                                    {
                                        values[index].map((attributeValue, index, array) => {
                                            if (attributeValue) {
                                                if (attribute.type === "MULTISELECT" || attribute.type === "SELECT") {
                                                    return (
                                                        <span key={index}>
                                                            <InternationalizationRender
                                                                locale={props.language}
                                                                value={attributeValue}
                                                                listInternationalization={props.allCodes}
                                                            />
                                                            {array.length - 1 !== index ? ', ' : ""}
                                                        </span>
                                                    )
                                                } else {
                                                    return (
                                                        attributeValue
                                                    )
                                                }
                                            } else {
                                                return "-"
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        )
                        : ""}
                    <div className="col-md mb-3 mx-3">
                        <div className="row font-weight-bold"><FormattedMessage id="project.elements.creationDate" /></div>
                        <div className="row">
                            <span>
                                <FormattedDate
                                    value={props.geometricElement.creationDate}
                                    day="numeric"
                                    month="numeric"
                                    year="numeric"
                                />&nbsp;
                                <FormattedTime
                                    value={props.geometricElement.creationDate}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="col-md mb-3 mx-3">
                        <div className="row font-weight-bold"><FormattedMessage id="project.elements.modificationDate" /></div>
                        <div className="row">
                            <span>
                                <FormattedDate
                                    value={props.geometricElement.modificationDate}
                                    day="numeric"
                                    month="numeric"
                                    year="numeric"
                                />&nbsp;
                                <FormattedTime
                                    value={props.geometricElement.modificationDate}
                                />
                            </span>
                        </div>
                    </div>
                    {props.authenticatedUser ?
                        <div className="col-md mb-3 mx-3">
                            <div className="row font-weight-bold"><FormattedMessage id="project.elements.isPublic" /></div>
                            <div className="row">
                                {props.geometricElement.isPublic ?
                                    <span><FormattedMessage id="project.common.yes" /></span> :
                                    <span><FormattedMessage id="project.common.no" /></span>}
                            </div>
                        </div>
                        : ""
                    }
                    {props.authenticatedUser ?
                        <div className="col-md mb-3 mx-3">
                            <div className="row font-weight-bold"><FormattedMessage id="project.elements.isReviewed" /></div>
                            <div className="row">
                                {props.geometricElement.isReviewed ?
                                    <span><FormattedMessage id="project.common.yes" /></span> :
                                    <span><FormattedMessage id="project.common.no" /></span>}
                            </div>
                        </div>
                        : ""
                    }
                    {props.authenticatedUser && props.authenticatedUser.userRoleDto.code === "ADMIN" ?
                        <div className="col-md mb-3 mx-3">
                            <div className="row font-weight-bold"><FormattedMessage id="project.elements.internalComments" /></div>
                            <div className="row">
                                {props.geometricElement.internalComments && props.geometricElement.internalComments !== "" ?
                                    props.geometricElement.internalComments :
                                    "-"}
                            </div>
                        </div>
                        : ""
                    }
                    {RELATE_ELEMENTS_TO_PARISHES === "true" ?
                        <div className="col-md mb-3 mx-3">
                            <div className="row font-weight-bold"><FormattedMessage id="project.common.location" /></div>
                            <div className="row">
                                <ul>
                                    {props.geometricElement.listParishes.map(parish =>
                                        <li>
                                            {parish.name} - {parish.municipality.name} - {parish.municipality.region.province.name}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        : ""
                    }
                    {props.authenticatedUser && props.authenticatedUser.userRoleDto.code === "ADMIN" ?
                        <div className="col-md mb-3 mx-3">
                            <div className="row font-weight-bold"><FormattedMessage id="project.elements.owner" /></div>
                            <div className="row">
                                {props.geometricElement.owner.login}
                            </div>
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>
    )

}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementData)));