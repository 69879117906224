import jQuery from "jquery";
import { getInternationalization } from "../app/components/InternationalizationRender";
import React from "react";

export const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toLocaleUpperCase() + rest.join('');
}

export const initActionButtonTooltip = () => {
    jQuery(function () {
        jQuery('.actionButton').tooltip({ 
            trigger: "hover",
            placement: 'right',
            boundary: 'window'
        });
    });

    jQuery(function () {
        jQuery('.actionButton').tooltip().click(function () {
            jQuery('.actionButton').tooltip("hide")
        });
    });
}

export const initTooltipHelp = () => {
    jQuery(function () {
        //show tooltip
        jQuery('.helpTooltip').tooltip({
            html: true,
            placement: 'right',
            trigger: 'click',
            // Prevent placement flip
            fallbackPlacement: ['right'],
            boundary: 'window',
            // Show tables and custom styles inside tooltip
            sanitize: false,
            template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                '</div><div class="help-tooltip tooltip-inner"></div></div>'
        });
        //hide it when clicking anywhere else
        jQuery('body').on('click', function (e) {
            jQuery('.helpTooltip').each(function () {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                    jQuery(this).tooltip('hide');
                }
            });
        });
    });

    //Prevent clicking on help button activates input associated with label
    jQuery(document).on('tap click', 'label sup', function (event, data) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    });
}

export const renderLanguageOptions = (activeLanguages, language, allCodes) => {
    return activeLanguages.map(languageCode => {
        let formattedLanguage;
        let splittedLanguage = languageCode.split("-");
        formattedLanguage = `${splittedLanguage[0].toLowerCase()}-${splittedLanguage[1]}`
        return (
            <option value={formattedLanguage} key={formattedLanguage}>
                {getInternationalization(language, languageCode, allCodes, activeLanguages)}
            </option>
        )
    })
}

export const sortGeometricElementAttributeValueComparator = (value1, value2) => {
    if (value1.elementTypeAttributeType.attributeOrder < value2.elementTypeAttributeType.attributeOrder) {
        return -1;
    }
    if (value2.elementTypeAttributeType.attributeOrder < value1.elementTypeAttributeType.attributeOrder) {
        return 1;
    }

    return 0;
}

export const sortGeometricElementTypeAttributeTypeComparator = (attribute1, attribute2) => {
    if (attribute1.attributeOrder < attribute2.attributeOrder) {
        return -1;
    }
    if (attribute2.attributeOrder < attribute1.attributeOrder) {
        return 1;
    }

    return 0;
}

export const sortValuesAndGroupByAttributeType = (attributeValueList) => {
    let attributeNameList = [];
    let attributes = [];
    let values = [];
    attributeValueList.sort(sortGeometricElementAttributeValueComparator);

    attributeValueList.forEach(attributeValue => {
        let code = attributeValue.elementTypeAttributeType.attributeType.code.code;
        let type = attributeValue.elementTypeAttributeType.attributeType.dataType;
        let attributeType = { code, type };
        if (attributeNameList.includes(code)) {
            let attributeTypeIndex = attributeNameList.indexOf(code);
            values[attributeTypeIndex].push(attributeValue.value);
        } else {
            attributeNameList.push(code);
            attributes.push(attributeType);
            values.push([attributeValue.value]);
        }
    });

    return { attributes, values }
}